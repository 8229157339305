import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Content } from '../../common';
import LoginForm from '../forms/LoginForm';
import { login } from '../../actions/users.actions';

export const LoginPage = ({ user, location }) => {
  const { isAuthenticated, role } = useSelector(state => state.user)
  const dispatch = useDispatch()

  if (isAuthenticated && role === 'admin') {
    const { state } = location;

    if (state && state.referrer) {
      return <Redirect to={state.referrer} />;
    }
    else {
      return <Redirect to="/admin/dashboard" />;
    }
  }

  const handleSubmit = ({ email, password }) => {
    dispatch(login(email, password));
  };

  return (
    <div id="login">
      <div className="container">
        <div className="row d-flex align-items-center justify-content-center">
          <Box className="block__login col-lg-6">
            <Content title="Přihlášení - Administrace" isMainTitle={true}>
              <LoginForm onSubmit={handleSubmit} />
            </Content>
          </Box>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
