import React from 'react';
import cx from 'classnames';
import './Box.scss'

const Box = ({ children, className }) => {
  return (
    <div className={cx('block', className)}>
      {children}
    </div>
  )
}

export default Box
