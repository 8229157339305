import React from 'react';
import { PropTypes as P } from 'prop-types';
import cx from 'classnames';
import TextEditor from '../TextEditor'

const renderInfoMessage = ({ error, warning }) => {
  const errorMessage = error && <div className="invalid-feedback">{error}</div>;
  const warningMessage = warning && <div className="invalid-feedback">{warning}</div>;

  return (
    errorMessage || warningMessage
  );
}

const FormTextEditor = ({ input, label, meta }) => {
  const handleTextChange = (value) => {
    input.onChange(value);
  }

  const isInvalid = () => {
    const { error, touched } = meta;

    return touched && error;
  }

  const className = cx('form-control', { 'is-invalid': isInvalid() });

  return (
    <div className="form-group">
      <label htmlFor={input.name}>{label}</label>
      <div className={className}>
        <TextEditor {...input} value={input.value} onTextChange={handleTextChange} />
      </div>
      {meta.touched && renderInfoMessage(meta)}
    </div>
  )
}

FormTextEditor.propTypes = {
  input: P.object.isRequired,
  label: P.string.isRequired,
  meta: P.object.isRequired,
}

export default FormTextEditor
