import React from 'react';
import { Header } from '../../common';
import { FileManager } from '../components'

const DocumentsPage = () => (
  <div id="main">
    <Header title="Dokumenty" />
    <div className="container-fluid">
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <FileManager />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default DocumentsPage


