import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Content, Grid, FormInput, FormTextEditor, FormSelect, Header } from '../../common';
import { loadResidence, submitResidence } from '../../actions/residences.actions';
import { loadContactNames } from '../../actions/contacts.actions';

const ResidenceFormPage = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { residence, isLoadingResidences } = useSelector(state => state.residences)
  const { contactNames, isLoading: isLoadingContacts } = useSelector(state => state.contacts)

  const title = residence?.id ? 'Editace rezidence' : 'Přidání nové rezidence';
  const contactNamesItems = contactNames.map(n => ({ id: n, name: n }));

  useEffect(() => {
    if (match.params.id) {
      dispatch(loadResidence(match.params.id))
    }
    dispatch(loadContactNames());
  }, [dispatch, match])

  if (isLoadingResidences || isLoadingContacts) return <div className='loader'>Načítám...</div>

  const onSubmit = (data) => {
    dispatch(submitResidence(data));
    history.push('/admin/residence');
  };

  return (
    <div id='main'>
      <Form
        onSubmit={onSubmit}
        initialValues={residence}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Header title={title}>
              <button className='btn btn-danger'
                onClick={() => {
                  history.push('/admin/residence');
                }}>Zpět</button>
              <button type='submit' className='btn btn-primary'>Uložit</button>
            </Header>
            <div className='content'>
              <Grid isScrollable={false}>
                <div>
                  <Box>
                    <Content title='Obecné informace'>
                      <Field
                        name={`name`}
                        type='text'
                        component={FormInput}
                        label='Jméno nove rezidence'
                        placeholder='Jméno nove rezidence'
                      />
                      <Field
                        name='city'
                        type='text'
                        component={FormInput}
                        label='Lokace'
                        placeholder='Lokace'
                      />
                      <Field
                        name={`contactAddress`}
                        component={FormTextEditor}
                        label='Kontaktní adresa'
                        placeholder='Kontaktní adresa'
                      />
                      <Field
                        name={`brokerName`}
                        type='text'
                        component={FormInput}
                        label='Zodpovědná osoba (Makléř)'
                        placeholder='Jméno zodpovedne osoby'
                      />
                      <Field
                        name={`brokerEmail`}
                        type='text'
                        component={FormInput}
                        label='Email makléře'
                        placeholder='Email makléře'
                      />

                    </Content>
                  </Box>
                </div>
                <div>
                  <Box>
                    <Content title='Přiřazení kontaktu'>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="electricalInstallation"
                            label="Elektroinstalace"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="plaster"
                            label="Omítky + zdivo"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="windows"
                            label="Okna"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="doors"
                            label="Vstupní dveře"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="doorsInterier"
                            label="Interiérové dveře"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="floors"
                            label="Podlahy"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="centralHeating"
                            label="Ústřední topení"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="ventilatory"
                            label="Ventilátory"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="obklady"
                            label="Obklady"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="waterInstallation"
                            label="Vodovodní instalace"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Field
                            name="doorbells"
                            label="Domovní zvonky"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="other"
                            label="Ostatní / Jiné"
                            component={FormSelect}
                            items={[{}, ...contactNamesItems]}
                          />
                        </div>
                      </div>
                    </Content>
                  </Box>
                </div>
              </Grid>
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default ResidenceFormPage
