import React from 'react'
import { required, requiredSelect } from '../../../validators/reclamationValidator';
import { Box, FormInput, FormSelect } from '../../../common';
import { Field } from 'react-final-form';

const ApartmentInformationSection = ({
  residence,
  residenceNames
}) => {
  return (
    <Box>
      <h3 className="h3-reclamaction">Informace o bytové jednotce</h3>
      <div className='row'>
        <div className='col-md-4'>
          <Field
            name='byt'
            type='text'
            component={FormInput}
            label='Číslo bytu / jednotky'
            placeholder='Zadejte číslo bytu'
            validate={required}
          />
        </div>
        <div className='col-md-4'>
          <Field
            disabled={!!residence}
            initialValue={`${residence?.id}`}
            name='rezidence'
            component={FormSelect}
            label='Název projektu (rezidence)'
            items={[{ name: 'Prosím vyberte' }, ...residenceNames]}
            validate={requiredSelect}
          />
        </div>
      </div>
    </Box>
  )
}

export default ApartmentInformationSection
