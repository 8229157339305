import React from 'react';
import { PropTypes as P } from 'prop-types';
import './Box.scss'

const Content = ({ children, className, title, isMainTitle }) => {
  const renderTitle = () => {
    if (!title) return null;
    return isMainTitle ? <h1>{title}</h1> : <h3>{title}</h3>;
  }

  return (
    <div className={className}>
      {renderTitle()}
      {children}
    </div>
  )
}

Content.propTypes = {
  isMainTitle: P.bool,
  className: P.string,
}

Content.defaultProps = {
  isMainTitle: false,
};

export default Content
