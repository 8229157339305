import uniqBy from 'lodash/uniqBy';
import remove from 'lodash/remove';

import {
    FILE_ADD,
    FILE_ADD_SUCCESS,
    FILE_ERROR,
    FILE_LOAD,
    FILE_LOAD_SUCCESS,
    FILE_REMOVE,
    FILE_REMOVE_SUCCESS,
} from '../actions/types';

const initialState = {
    items: [],
    isLoading: false,
    error: null,
};

const files = (state = initialState, action) => {
    switch (action.type) {
        case FILE_LOAD:
        case FILE_ADD:
        case FILE_REMOVE:
            return { ...state, isLoading: true, error: null };
        case FILE_LOAD_SUCCESS:
            return { ...state, items: action.payload.data.files, isLoading: false, error: null };
        case FILE_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                items: uniqBy([...state.items, ...action.payload.data.files], 'path')
            };
        case FILE_REMOVE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                items: remove(state.items, (item) => item.path !== decodeURI(action.payload.data.path)),
            };
        case FILE_ERROR:
            return { ...state, isLoading: false, items: [], error: action.payload.message };
        default:
            return state;
    }
};

export default files;
