import React from 'react';
import { PropTypes as P } from 'prop-types';
import commonIcon from '../../assets/icons/ico-question.svg';
import cx from 'classnames';
import './Dialog.scss'

const ConfirmDialog = ({ id, show, onProceed, onCancel, iconPath, title, text, confirmText, cancelText }) => {
  if (!id) return null;

  return (
    <div className={cx("overlay_modal", { "show": show })}>
      <div className="popup">
        <img src={iconPath} alt="ico" />

        <h3>{title}</h3>
        {Boolean(text) && <p>{text}</p>}

        <button
          className="btn button-gray"
          type="button" disabled=""
          onClick={onCancel}
        >
          {cancelText}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => onProceed(id)}
        >
          {confirmText}
        </button>
      </div>
    </div>
  );
}

ConfirmDialog.propTypes = {
  id: P.any.isRequired,
  show: P.bool.isRequired,
  onProceed: P.func.isRequired,
  onCancel: P.func.isRequired,
  iconPath: P.string,
  title: P.string.isRequired,
  text: P.string,
  confirmText: P.string,
  cancelText: P.string,
}

ConfirmDialog.defaultProps = {
  iconPath: commonIcon,
  text: null,
  confirmText: 'Potvrdit',
  cancelText: 'Zrušit',
}

export default ConfirmDialog
