import React from 'react';
import { Box, FormWrapper, Title } from '../../common';
import successImg from '../../assets/icons/spinner.svg';

const SubmittingPage = () => {
  return (
    <React.Fragment>
      <FormWrapper class='form-wrapper'>
        <Title title={'Reklamační formulář'} />
        <Box className="submittedInfo">
          <img src={successImg} alt="success icon" />
          <span className='span-header' >Vaše reklamace se nahrává, prosím čekejte…</span>
          <span className='span-text' >Probíhá nahrávání příloh, vyčkejte prosím než se reklamace nahraje. Poté budete automaticky přesměrováni.</span>
        </Box>
      </FormWrapper>
    </React.Fragment>
  );
}

export default SubmittingPage
