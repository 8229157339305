import React, { useState } from 'react';
import { PropTypes as P } from 'prop-types';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { renderInfoMessage, isInvalid, getDateWithTime } from '../utils';
import './FormDatePicker.scss'

const FormDatePicker = ({ input, label, meta }) => {
  const [selected, setSelected] = useState(null)
  const [val, setVal] = useState(undefined)

  const { type, name } = input

  const className = cx('form-control', { 'is-invalid': isInvalid(meta) });
  const classNameSelectInput = cx("select-input", { 'select-input-date': (type === 'date') }, { 'select-input-time': !(type === 'date') });

  const handleChangeDate = date => {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    input.onChange(formattedDate);
    setVal(formattedDate)
  };

  const handleChangeTime = date => {
    input.onChange(moment(date).format('HH:mm'));
    setSelected(date)
  };

  return (
    <div className={cx('form-group', { 'is-invalid': isInvalid(meta) })}>
      <label htmlFor={name}>{label}</label>
      <div className={classNameSelectInput}>
        {type === 'date' ? (
          <DatePicker
            className={className}
            onChange={handleChangeDate}
            maxDate={new Date()}
            value={val}
            placeholderText="DD/MM/YYYY"
            onBlur={input.onBlur}
          />
        ) : (
            <DatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="HH:mm"
              timeFormat="HH:mm"
              timeCaption="Termín"
              minTime={getDateWithTime(8, 0)}
              maxTime={getDateWithTime(16, 30)}
              className={className}
              onChange={handleChangeTime}
              selected={selected}
              placeholderText="HH:MM"
              onBlur={input.onBlur}
            />
          )
        }
      </div>
      {meta.touched && renderInfoMessage(meta)}
    </div>
  )
}

FormDatePicker.propTypes = {
  val: P.string,
  input: P.object.isRequired,
  type: P.string,
  meta: P.object.isRequired,
  label: P.string,
}

export default FormDatePicker
