
import ContactService from '../services/contacts.service';
import { addNotification } from './notification.actions';
import {
  LOAD_CONTACTS,
  LOAD_CONTACTS_SUCCESS,
  CONTACT_ERROR,
  LOAD_CONTACT,
  LOAD_CONTACT_SUCCESS,
  DELETE_CONTACT,
  CLEAR_CONTACT,
  LOAD_CONTACT_NAMES,
  LOAD_CONTACT_CATEGORIES,
} from './types';

export function submitContact(data) {
  return async dispatch => {
    try {

      await ContactService.submit(data);

      dispatch(addNotification('Kontakt je uložen', true));
      dispatch({ type: LOAD_CONTACTS });

      const request = await ContactService.list();

      dispatch({
        type: LOAD_CONTACTS_SUCCESS,
        payload: request,
      });
    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kontakt se nepodařilo načíst.', false));
    }
  };
}

export function loadContacts() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_CONTACTS });

      const request = await ContactService.list();

      dispatch({
        type: LOAD_CONTACTS_SUCCESS,
        payload: request,
      });

    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kontakt se nepodařilo načíst.', false));
    }
  };
}

export function loadContactNames() {
  return async dispatch => {
    try {
      const contactNames = await ContactService.getContactNames();

      dispatch({
        type: LOAD_CONTACT_NAMES,
        payload: contactNames,
      });

    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kontakt se nepodařilo načíst.', false));
    }
  };
}

export function loadContactCategories() {
  return async dispatch => {
    try {
      const categories = await ContactService.getContactCategories();

      dispatch({
        type: LOAD_CONTACT_CATEGORIES,
        payload: categories,
      });

    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kategorie se nepodařilo načíst.', false));
    }
  };
}

export function loadContact(id) {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_CONTACT });

      const request = await ContactService.getByID(id);

      dispatch({
        type: LOAD_CONTACT_SUCCESS,
        payload: request,
      });
    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kontakt se nepodařilo načíst.', false));
    }
  };
}

export function deleteContact(id) {
  return async dispatch => {
    try {
      dispatch({ type: DELETE_CONTACT });

      await ContactService.deleteContact(id);

      const request = await ContactService.list();

      dispatch({
        type: LOAD_CONTACTS_SUCCESS,
        payload: request,
      });
    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kontakt se nepodařilo načíst.', false));
    }
  };
}

export function clearContact() {
  return async dispatch => {
    try {
      dispatch({ type: CLEAR_CONTACT });
    }
    catch (e) {
      dispatch({
        type: CONTACT_ERROR,
        payload: e,
      });

      dispatch(addNotification('Kontakt se nepodařilo načíst.', false));
    }
  };
}
