import {
  ADD_RESIDENCE,
  ADD_RESIDENCE_SUCCESS,
  RESIDENCE_ERROR,
  LOAD_RESIDENCES,
  LOAD_RESIDENCES_SUCCESS,
  DELETE_RESIDENCE,
  DELETE_RESIDENCE_SUCCESS,
  EDIT_RESIDENCE,
  EDIT_RESIDENCE_SUCCESS,
  LOAD_RESIDENCE,
  LOAD_RESIDENCE_SUCCESS,
  LOAD_RESIDENCE_NAMES,
  LOAD_RESIDENCE_NAMES_SUCCESS,
  LOAD_RESIDENCE_CITIES,
  LOAD_RESIDENCE_CITIES_SUCCESS,
  LOAD_RESIDENCES_BY_CITY,
  LOAD_RESIDENCES_BY_CITY_SUCCESS
} from '../actions/types'

const initialState = {
  residences: [],
  residenceNames: [],
  byCity: {},
  residenceCities: [],
  isLoading: false,
  isLoadingResidences: false,
  isLoadingResidenceNames: false,
  isLoadingResidenceCities: false,
  isLoadingResidencesByCity: false,
  residence: null,
  error: null
}

const residences = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESIDENCE:
    case DELETE_RESIDENCE:
    case EDIT_RESIDENCE:
    case LOAD_RESIDENCE:
      return { ...state, isLoading: true }
    case LOAD_RESIDENCES_BY_CITY:
      return { ...state, isLoadingResidencesByCity: true }
    case LOAD_RESIDENCES:
      return { ...state, isLoadingResidences: true }
    case LOAD_RESIDENCE_NAMES:
      return { ...state, isLoadingResidenceNames: true }
    case LOAD_RESIDENCE_CITIES:
      return { ...state, isLoadingResidenceCities: true }
    case ADD_RESIDENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        residences: [...state.residences, action.payload.data.residence]
      }
    case DELETE_RESIDENCE_SUCCESS:
      return {
        ...state,
        isLoading: false
      }
    case EDIT_RESIDENCE_SUCCESS:
      return { ...state, isLoading: false }
    case LOAD_RESIDENCES_SUCCESS:
      return {
        ...state,
        isLoadingResidences: false,
        residences: action.payload.data.residences
      }
    case LOAD_RESIDENCE_CITIES_SUCCESS:
      return {
        ...state,
        isLoadingResidenceCities: false,
        residenceCities: action.payload.data.residenceCities
      }
    case LOAD_RESIDENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        residence: action.payload.data.residence
      }
    case LOAD_RESIDENCE_NAMES_SUCCESS:
      return {
        ...state,
        isLoadingResidenceNames: false,
        residenceNames: action.payload.data.residenceNames
      }
    case LOAD_RESIDENCES_BY_CITY_SUCCESS:
      return {
        ...state,
        isLoadingResidencesByCity: false,
        byCity: action.payload.residencesByCity
      }
    case RESIDENCE_ERROR:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}

export default residences
