import React from 'react';
import { PropTypes as P } from 'prop-types';
import { renderInfoMessage } from '../utils';
import useDidMount from '../../hooks/useDidMount'

const FormSlider = ({ input, meta, text, val }) => {
  useDidMount(() => {
    if (val) input.onChange(val);
  })

  return (
    <React.Fragment>
      <label className="switch">
        <input type="checkbox" {...input} />
        <span className="slider round">
          <label className='slider-label'>NE</label>
        </span>
      </label>
      {text && <small id='checkbox-label'>{text}</small>}
      {meta.touched && renderInfoMessage(meta)}
    </React.Fragment>
  );
}

FormSlider.propTypes = {
  val: P.string,
  input: P.object.isRequired,
  meta: P.object.isRequired,
  label: P.string,
  placeholder: P.string,
  text: P.string,
}

export default FormSlider
