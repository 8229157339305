import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user';
import apartmentReducer from './apartments';
import configuratorReducer from './configurator';
import infoBoxReducer from './info-box';
import residencesReducer from './residences';
import filesReducer from './files';
import notificationReducer from './notification';
import reclamationsReducer from './reclamations';
import contactsReducer from './contacts';

const rootReducer = combineReducers({
    apartments: apartmentReducer,
    residences: residencesReducer,
    user: userReducer,
    form: formReducer,
    configurator: configuratorReducer,
    infoBoxes: infoBoxReducer,
    files: filesReducer,
    notification: notificationReducer,
    reclamations: reclamationsReducer,
    contacts: contactsReducer,
});

export default rootReducer;
