import React from 'react'
import { PropTypes as P } from 'prop-types'
import cx from 'classnames'
import Button from '../Button'
import './GroupButtons'

const GroupButtons = ({ btnList, onClick, activeButton, className }) => {
  return (
    <div className={`groupButtons ${className}`}>
      <ul className="nav nav-pills">
        {btnList.map((button) => {
          const { id, name } = button;

          return (
            <li className="nav-item" key={id}>
              <Button
                key={id}
                id={id}
                className={cx('btn nav-link tab_button', { 'active': activeButton === id })}
                onClick={onClick}>
                {name}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  )
}

GroupButtons.propTypes = {
  activeButton: P.string.isRequired,
  onClick: P.func,
  btnList: P.array
}

GroupButtons.defaultProps = {
  onClick() { }
}

export default GroupButtons
