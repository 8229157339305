import React from 'react';
import { PropTypes as P } from 'prop-types';
import url from 'url';
import cx from 'classnames';
import { domain } from '../../../services/config';
import pdfIcon from '../../../assets/icons/pdf_ico.svg';
import docIcon from '../../../assets/icons/doc_ico.svg';
import xlsIcon from '../../../assets/icons/xls_ico.svg';
import folderIcon from '../../../assets/icons/folder_ico.svg';
import defaultFileIco from '../../../assets/icons/defaultFile_ico.svg'
import './FileManager.scss'

const FileItem = ({
  path,
  name,
  type,
  isSelected,
  onClick,
  onDoubleClick,
  onDeleteClick,
}) => {
  const className = cx('file-item', { 'file-item--selected': isSelected });

  const resolveIcon = (type, path) => {
    switch (type) {
      case 'pdf': return pdfIcon;
      case 'folder': return folderIcon;
      case 'xls', 'xlsx', 'csv': return xlsIcon;
      case 'doc', 'docx': return docIcon;
      case 'image': return url.resolve(domain, path);
      default: return defaultFileIco;
    }
  }

  const handleClick = () => {
    onClick(type, path);
  };

  const handleDoubleClick = () => {
    onDoubleClick(type, path);
  };

  const handleDeleteClick = () => {
    onDeleteClick(path);
  };

  return (
    <div className={className} onClick={handleClick} onDoubleClick={handleDoubleClick}>
      <button className="file-item__delete-btn" onClick={handleDeleteClick}></button>
      <img className="file-item__image" src={resolveIcon(type, path)} alt={path} />
      <span title={name} className="file-item__name">{name}</span>
    </div>
  )
}

FileItem.propTypes = {
  path: P.string.isRequired,
  name: P.string.isRequired,
  type: P.oneOf(['pdf', 'image', 'folder', 'generic', 'doc', 'docx', 'xls', 'xlsx', 'csv']),
  isSelected: P.bool,
  onClick: P.func,
  onDoubleclick: P.func,
  onDeleteClick: P.func,
}

FileItem.defaultProps = {
  type: 'generic',
  isSelected: false,
  onClick() { },
  onDoubleClick() { },
  onDeleteClick() { },
};

export default FileItem
