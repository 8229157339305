import axios from 'axios';

import { API_ROOT, setAuthHeader } from './config';

export default class ContactService {
    static submit(data) {
        return axios.post(`${API_ROOT}/admin/contact/submit`, data, { headers: setAuthHeader() });
    }
    static list() {
        return axios.get(`${API_ROOT}/contact/list`);
    }
    static getContactNames() {
        return axios.get(`${API_ROOT}/contact/names`);
    }
    static getContactCategories() {
        return axios.get(`${API_ROOT}/admin/contact/categories`, { headers: setAuthHeader() });
    }
    static getByID(id) {
        return axios.get(`${API_ROOT}/admin/contact/edit/${id}`, { headers: setAuthHeader() });
    }
    static deleteContact(id) {
        return axios.get(`${API_ROOT}/admin/contact/delete/${id}`, { headers: setAuthHeader() });
    }
}
