import {
  ADD_RECLAMATION,
  ADD_RECLAMATION_SUCCESS,
  RECLAMATION_ERROR,
  LOAD_RECLAMATIONS,
  LOAD_RECLAMATIONS_SUCCESS,
  DELETE_RECLAMATION,
  DELETE_RECLAMATION_SUCCESS,
  EDIT_RECLAMATION,
  EDIT_RECLAMATION_SUCCESS,
  LOAD_RECLAMATION,
  LOAD_RECLAMATION_SUCCESS,
  LOAD_CATEGORIES,
  LOAD_STATUSES,
  LOAD_STATUSES_SUCCESS,
  LOAD_RECLAMATION_FILES,
  LOAD_CATEGORIES_SUCCESS
} from '../actions/types'

// TODO (Armana): separate statuses, categories and files into its own state

const initialState = {
  reclamations: [],
  categories: [],
  reclamation: null,
  error: null,
  files: [],
  statuses: [],
  isLoadingReclamations: false,
  isLoadingReclamation: false,
  isLoadingStatuses: false,
  isLoadingCategories: false,
  isLoadingFiles: false,
  isLoading: false,
}

const reclamations = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RECLAMATION:
    case DELETE_RECLAMATION:
    case EDIT_RECLAMATION:
      return { ...state, isLoading: true }
    case LOAD_RECLAMATIONS:
      return { ...state, isLoadingReclamations: true }
    case LOAD_RECLAMATION:
      return { ...state, isLoadingReclamation: true }
    case LOAD_CATEGORIES:
      return { ...state, isLoadingCategories: true }
    case LOAD_STATUSES:
      return { ...state, isLoadingStatuses: true }
    case ADD_RECLAMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reclamations: [...state.reclamations, action.payload.data.reclamation]
      }
    case DELETE_RECLAMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reclamations: state.reclamations.filter(
          reclamation => reclamation.id !== action.payload.data.reclamation.id
        )
      }
    case EDIT_RECLAMATION_SUCCESS:
      return { ...state, isLoading: false }
    case LOAD_RECLAMATIONS_SUCCESS:
      return {
        ...state,
        isLoadingReclamations: false,
        reclamations: action.payload.data.reclamations
      }
    case LOAD_RECLAMATION_SUCCESS:
      return {
        ...state,
        isLoadingReclamation: false,
        reclamation: action.payload.data.reclamation
      }
    case LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoadingCategories: false,
        categories: action.payload.data.categories
      }
    case LOAD_STATUSES_SUCCESS:
      return {
        ...state,
        isLoadingStatuses: false,
        statuses: action.payload.data.statuses
      }
    case LOAD_RECLAMATION_FILES:
      return { ...state, files: action.payload.data.files.map(f => f.path) }
    case RECLAMATION_ERROR:
      return {
        ...state,
        isLoadingReclamations: false,
        isLoadingReclamation: false,
        isLoadingStatuses: false,
        isLoadingCategories: false,
        isLoadingFiles: false,
        isLoading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default reclamations
