import {
  RESIDENCE_ERROR,
  LOAD_RESIDENCES,
  LOAD_RESIDENCES_SUCCESS,
  DELETE_RESIDENCE,
  DELETE_RESIDENCE_SUCCESS,
  LOAD_RESIDENCE,
  LOAD_RESIDENCE_SUCCESS,
  LOAD_RESIDENCE_NAMES,
  LOAD_RESIDENCE_NAMES_SUCCESS,
  LOAD_RESIDENCES_BY_CITY,
  LOAD_RESIDENCE_CITIES,
  LOAD_RESIDENCE_CITIES_SUCCESS,
  LOAD_RESIDENCES_BY_CITY_SUCCESS
} from './types'

import ResidencesService from '../services/residences.service'
import { addNotification } from './notification.actions'
import { map } from 'lodash'

export function submitResidence(residence) {
  return async dispatch => {
    try {
      await ResidencesService.submit(residence)

      dispatch({ type: LOAD_RESIDENCES })

      const list = await ResidencesService.load()

      dispatch({
        type: LOAD_RESIDENCES_SUCCESS,
        payload: list
      })

      dispatch(addNotification('Rezidence vytvořena.', true))
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e
      })

      dispatch(addNotification('Rezidenci se nepodařilo vytvořit.', false))
    }
  }
}

export function loadResidences() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RESIDENCES })

      const request = await ResidencesService.load()

      dispatch({
        type: LOAD_RESIDENCES_SUCCESS,
        payload: request
      })
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e
      })

      dispatch(addNotification('Rezidence se nepodařilo načíst.', false))
    }
  }
}

export function loadCities() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RESIDENCE_CITIES })

      const request = await ResidencesService.getCities()

      dispatch({
        type: LOAD_RESIDENCE_CITIES_SUCCESS,
        payload: request
      })
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e
      })
    }
  }
}

export function loadResidencesByCity() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RESIDENCES_BY_CITY })
      const request = await ResidencesService.getCities()
      const residentCities = request.data.residenceCities

      const residencesByCity = map(residentCities, async city => {
        const residences = await ResidencesService.loadByCity(city)
        return {
          [city]: residences.data.residences
        }
      })

      dispatch({
        type: LOAD_RESIDENCES_BY_CITY_SUCCESS,
        payload: residencesByCity
      })
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e
      })
    }
  }
}

export function loadResidence(id) {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RESIDENCE })

      const request = await ResidencesService.loadById(id)

      dispatch({
        type: LOAD_RESIDENCE_SUCCESS,
        payload: request
      })
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e
      })

      dispatch(addNotification('Rezidenci se nepodařilo načíst.', false))
    }
  }
}

export function deleteResidence(id) {
  return async dispatch => {
    try {
      dispatch({ type: DELETE_RESIDENCE })

      const request = await ResidencesService.delete(id)

      dispatch({
        type: DELETE_RESIDENCE_SUCCESS,
        payload: request
      })
      dispatch({ type: LOAD_RESIDENCES })

      const list = await ResidencesService.load()

      dispatch({
        type: LOAD_RESIDENCES_SUCCESS,
        payload: list
      })

      dispatch(addNotification('Rezidence smazána.', true))
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e.message
      })

      dispatch(addNotification('Rezidenci se nepodařilo smazat.', false))
    }
  }
}

export function loadResidenceNames() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RESIDENCE_NAMES })

      const residenceNames = await ResidencesService.getResidenceNames()

      dispatch({
        type: LOAD_RESIDENCE_NAMES_SUCCESS,
        payload: residenceNames
      })
    } catch (e) {
      dispatch({
        type: RESIDENCE_ERROR,
        payload: e
      })

      dispatch(addNotification('Contact se nepodařilo načíst.', false))
    }
  }
}
