import { useEffect } from 'react';
import { isFunction } from 'lodash';

const useDidMount = (callback) => {
  useEffect(() => {
    if (isFunction(callback)) callback();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDidMount;
