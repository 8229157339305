import React from 'react';
import { Box, FormWrapper, Title } from '../../common';
import successImg from '../../assets/icons/notification-success.svg';
import { useHistory } from 'react-router-dom';

const SubmittedPage = () => {
  const history = useHistory()

  return (
    <React.Fragment>
      <FormWrapper class='form-wrapper'>
        <Title title={'Reklamační formulář'} />
        <Box className="submittedInfo">
          <img src={successImg} alt="success icon" />
          <span className='span-header' >Reklamace odeslána</span>
          <span className='span-text' >Potvrzujeme přijetí rezervace, v nejbližší době budete kontaktování o stavu její vyřízení.</span>
          <button type='submit' className='btn btn-primary' onClick={() => history.push('/')}>Podat novou reklamaci</button>
        </Box>
      </FormWrapper>
    </React.Fragment>
  )
}

export default SubmittedPage
