import React, { createRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormWrapper, Title, ConfirmDialog } from '../../../common'
import { submitReclamation, loadCategories } from '../../../actions/reclamations.actions';
import { loadResidences } from '../../../actions/residences.actions';
import icoQuestion from '../../../assets/icons/ico-question.svg';
import { find } from 'lodash'
import OwnerContactInformationSection from './OwnerContactInformationSection';
import ApartmentInformationSection from './ApartmentInformationSection';
import DefectInformationSection from './DefectInformationSection'
import { getTodaysDateString } from '../../../common/utils'
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';

const ReclamationPage = ({ match }) => {
  const { id: encodedResidenceIdFromURL } = match.params

  if (!encodedResidenceIdFromURL) {
    window.location.href = 'https://www.reklamacni-formular.cz';
  }

  const residenceBuffer = Buffer.from(encodedResidenceIdFromURL, 'base64');
  const residencePath = residenceBuffer.toString('ascii');
  const residenceId = residencePath.substring(residencePath.indexOf(":") + 1);

  const [dialog, setDialog] = useState(null)
  const [filesChoosen, setFilesChoosen] = useState([])
  const [files, setFiles] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()

  const residencesState = useSelector(state => state.residences)
  const reclamationsState = useSelector(state => state.reclamations)

  const {
    isLoadingResidences,
    isLoadingResidenceNames,
    isLoading: isLoadingResidencesState,
    residences
  } = residencesState
  const {
    isLoadingReclamations,
    isLoadingCategories,
    isLoading: isLoadingReclamationsState,
    categories
  } = reclamationsState

  const residenceNamesItems = residences.map(r => ({ id: r.id, name: r.name })) || []
  const categoriesItems = categories.map(n => ({ id: n.label, name: n.label })) || []

  const residence = find(residences, residence => String(residence.id) === residenceId)

  const isLoading =
    isLoadingReclamations ||
    isLoadingCategories ||
    isLoadingResidences ||
    isLoadingResidenceNames ||
    isLoadingResidencesState ||
    isLoadingReclamationsState

  const fileInput = createRef()

  useEffect(() => {
    dispatch(loadResidences());
    dispatch(loadCategories());
  }, [dispatch])

  if (isLoading) return <div className='loader'>Načítám...</div>

  const handleSubmitForm = (data) => {
    dispatch(submitReclamation({ ...data, files, datumPodani: getTodaysDateString() }, history));
    if (history) {
      history.push({
        pathname: '/submitting',
        state: { submitting: true },
      });
    }
  };

  const handleSubmitPopup = (data) => {
    setDialog({
      id: 'handleSubmitPopup',
      show: true,
      onProceed: () => handleSubmitForm(data),
      onCancel: dialogReset,
      iconPath: icoQuestion,
      title: `Potvrzení reklamace?`,
      text: 'Prosím ujištete se, zda jste vyplnili správné údaje a závada je dostatečně popsána. Urychlí to vyřízení celé reklamace.',
      confirmText: 'Odeslat reklamaci',
      cancelText: 'Znovu zkontrolovat',
    })
  };

  const dialogReset = () => setDialog(null);

  const chooseFile = () => {
    fileInput.current.click();
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const newFiles = [...e.target.files, ...files];

    setFiles(newFiles)
    setFilesChoosen([...filesChoosen, e.target.value])
  };

  const handleRemove = (filePath) => {
    const selectedFilesPaths = filesChoosen.filter(val => val !== filePath);
    const selectedFiles = files.filter((f) => selectedFilesPaths.some((s) => s.includes(f.name)));

    this.setState({ filesChoosen: selectedFilesPaths, files: selectedFiles });
  };

  return (
    <React.Fragment>
      <FormWrapper class='form-wrapper'>
        <Form
          onSubmit={handleSubmitPopup}
          render={({ handleSubmit, values }) => {
            const secondContact = values['secondContact']

            return (
              <form onSubmit={handleSubmit}>
                <Title title={'Reklamační formulář'} />
                <OwnerContactInformationSection secondContact={secondContact} />
                <ApartmentInformationSection
                  residence={residence}
                  residenceNames={residenceNamesItems}
                />
                <DefectInformationSection
                  categoriesNames={categoriesItems}
                  filesChoosen={filesChoosen}
                  fileInput={fileInput}
                  handleFileChange={handleFileChange}
                  handleChooseFileClick={chooseFile}
                  handleRemoveFileClick={handleRemove}
                />
              </form>
            )
          }} />
      </FormWrapper>
      {dialog && dialog.show && <ConfirmDialog {...dialog} />}
    </React.Fragment>
  );
}

export default ReclamationPage
