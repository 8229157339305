import React from 'react'
import { required, requiredSelect } from '../../../validators/reclamationValidator';
import { Box, FilesList, FormTextarea, FormSelect, FormDatePicker } from '../../../common';
import { Field } from 'react-final-form';

const DefectInformationSection = ({
  categoriesNames,
  filesChoosen,
  fileInput,
  handleFileChange,
  handleChooseFileClick,
  handleRemoveFileClick
}) => {

  const renderFileHandler = () => {
    return (
      <div className='form-group'>
        <input
          type="file"
          accept=".xls,.xlsx,.doc,.docx,.pdf,.png,.jpg,.jpeg,.svg"
          className="hiddenInput"
          ref={fileInput}
          onChange={handleFileChange}
          value={""}
          hidden={true}
        />

        {(filesChoosen.length)
          ? <FilesList
            link={true}
            files={filesChoosen}
            areFilesDeletable={true}
            onRemove={handleRemoveFileClick}
          />
          : <p>Žádné přiřazené soubory</p>}

        <button
          onClick={handleChooseFileClick}
          type="button"
          className='file-select__btn btn btn-primary'
        >
          + Přidat soubor
        </button>
      </div>
    );
  }
  return (
    <Box>
      <h3 className="h3-reclamaction">Závada</h3>
      <div className='row'>
        <div className='col-md-4'>
          <Field
            name='datumZjisteni'
            type='date'
            component={FormDatePicker}
            label='Datum zjištění závady'
            validate={required}
          />
        </div>
        <div className='col-md-4'>
          <Field
            name='kategorie'
            component={FormSelect}
            label='Kategorie závady'
            items={[
              { name: 'Prosím vyberte' },
              ...categoriesNames,
            ]}
            validate={requiredSelect}
          />
        </div>
        <div className='col-md-4'>
          <Field
            name='prohlidka'
            component={FormDatePicker}
            label='Návrh termínu prohlídky (8 – 17h)'
            type='time'
            validate={required}
          />
        </div>
      </div>
      <div className='row'>
        <Field
          name='zavada'
          component={FormTextarea}
          label='Popište prosím závadu'
          validate={required}
        />
      </div>
      <h3 className="h3-reclamaction">Fotografie reklamované závady</h3>

      <div className='row col-md-12'>
        {renderFileHandler()}
      </div>
      <div className='summary'>
        <div className='row'>
          <div className='col-md-12'>
            <button type='submit' className='btn btn-primary submit_btn btn-font'>Odeslat reklamaci</button>
          </div>
          <div className='col-md-12'>
            <p className='small-note'><small>Odesláním souhlasíte se zpracováním osobních údajů pro vytvoření reklamace.</small></p>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default DefectInformationSection
