import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ checked, id, onChange, }) => {
  const toggleCheckboxChange = () => {
    onChange(id)
  }

  return (
    <input
      label={id}
      type='checkbox'
      checked={checked}
      onChange={toggleCheckboxChange}
    />
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func
}

export default Checkbox
