import React, { createRef, useEffect, useCallback } from 'react'
import { PropTypes as P } from 'prop-types';
import cx from 'classnames';
import './Grid.scss'

const FIXED_CLASS = 'fixed';
const OFFSET_CLASS = 'offset-md-6';
const SCROLL_THRESHOLD = 45;

const Grid = ({ children, isScrollable, halfGrid }) => {
  const colFixed = createRef()
  const colStatic = createRef()
  const scrollRef = createRef()

  const activateFixedBox = useCallback(
    (isActive) => {
      if (colFixed && colStatic) {
        const { classList: colFixedClassList } = colFixed.current;
        const { classList: colStaticClassList } = colStatic.current;

        if (isActive) {
          colFixedClassList.add(FIXED_CLASS);
          colStaticClassList.add(OFFSET_CLASS);
        }
        else {
          colFixedClassList.remove(FIXED_CLASS);
          colStaticClassList.remove(OFFSET_CLASS);
        }
      }
    },
    [colFixed, colStatic],
  )

  const handleScroll = useCallback(
    () => {
      activateFixedBox(window.scrollY >= SCROLL_THRESHOLD)
    },
    [activateFixedBox],
  );

  useEffect(() => {
    if (isScrollable) window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isScrollable, handleScroll])

  const renderTwoColumns = () => (
    <div className='row'>
      <div className={cx({ 'col-md-6': halfGrid }, { 'col-md-5': !halfGrid }, { 'colFixed': isScrollable })} ref={colFixed}>
        {children[0]}
      </div>
      <div className={cx({ 'col-md-6': halfGrid }, { 'col-md-7': !halfGrid }, { 'colNormal': isScrollable })} ref={colStatic}>
        {children[1]}
      </div>
    </div>
  )

  const renderOneColumn = () => (
    <div className='row'>
      <div className='col-md-12'>
        {children}
      </div>
    </div>
  )

  return (
    <div className='container-fluid' ref={scrollRef}>
      {children.length === 2 ? renderTwoColumns() : renderOneColumn()}
    </div>
  )
}

Grid.propTypes = {
  isScrollable: P.bool,
  halfGrid: P.bool
}

Grid.defaultProps = {
  isScrollable: false,
  halfGrid: true
}

export default Grid
