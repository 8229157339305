import React from 'react'
import { PropTypes as P } from 'prop-types'
import './Header.scss'

const Header = ({ children, title, text }) => (
  <div className="navigace fixed-top d-flex align-items-center">
    <div className="container-fluid">
      <div className="row d-flex align-items-center">
        <div className="col-md-6">
          <h1>{title}</h1>
          {text && <p className="clientInfo">{text}</p>}
        </div>
        <div className="col-md-6 text-right">
          {children}
        </div>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  title: P.string.isRequired,
  text: P.string
}

Header.defaultProps = {
  text: ""
}

export default Header
