import React from 'react'
import { PropTypes as P } from 'prop-types'
import cx from 'classnames'
import './Tabs.scss'

const Tab = ({ isActive, id, children }) => {
  return (
    <div className={cx('tab-pane fade', { 'active show': isActive })} id={id} >
      {children}
    </div>
  )
}

Tab.propTypes = {
  id: P.string.isRequired,
  isActive: P.bool
}

Tab.defaultProps = {
  isActive: false
}

export default Tab
