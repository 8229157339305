export const USER_REQUEST = 'USER_REQUEST'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_ERROR = 'USER_ERROR'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_FROM_TOKEN = 'USER_FROM_TOKEN'
export const USER_FROM_TOKEN_SUCCESS = 'USER_FROM_TOKEN_SUCCESS'
export const USER_FROM_TOKEN_ERROR = 'USER_FROM_TOKEN_ERROR'

export const ADD_RESIDENCE = 'ADD_RESIDENCE'
export const ADD_RESIDENCE_SUCCESS = 'ADD_RESIDENCE_SUCCESS'
export const RESIDENCE_ERROR = 'RESIDENCE_ERROR'
export const LOAD_RESIDENCES = 'LOAD_RESIDENCES'
export const LOAD_RESIDENCES_SUCCESS = 'LOAD_RESIDENCES_SUCCESS'
export const LOAD_RESIDENCE = 'LOAD_RESIDENCE'
export const LOAD_RESIDENCE_SUCCESS = 'LOAD_RESIDENCE_SUCCESS'
export const LOAD_RESIDENCES_BY_CITY = 'LOAD_RESIDENCES_BY_CITY'
export const LOAD_RESIDENCES_BY_CITY_SUCCESS = 'LOAD_RESIDENCES_BY_CITY_SUCCESS'
export const EDIT_RESIDENCE = 'EDIT_RESIDENCE'
export const EDIT_RESIDENCE_SUCCESS = 'EDIT_RESIDENCE_SUCCESS'
export const DELETE_RESIDENCE = 'DELETE_RESIDENCE'
export const DELETE_RESIDENCE_SUCCESS = 'DELETE_RESIDENCE_SUCCESS'
export const LOAD_RESIDENCE_NAMES = 'LOAD_RESIDENCE_NAMES'
export const LOAD_RESIDENCE_NAMES_SUCCESS = 'LOAD_RESIDENCE_NAMES_SUCCESS'
export const LOAD_RESIDENCE_CITIES = 'LOAD_RESIDENCE_CITIES'
export const LOAD_RESIDENCE_CITIES_SUCCESS = 'LOAD_RESIDENCE_CITIES_SUCCESS'
export const OPEN_FINAL_STAGE = 'OPEN_FINAL_STAGE'
export const OPEN_FINAL_STAGE_SUCCESS = 'OPEN_FINAL_STAGE_SUCCESS'

export const ADD_RECLAMATION = 'ADD_RECLAMATION'
export const ADD_RECLAMATION_SUCCESS = 'ADD_RECLAMATION_SUCCESS'
export const RECLAMATION_ERROR = 'RECLAMATION_ERROR'
export const LOAD_RECLAMATIONS = 'LOAD_RECLAMATIONS'
export const LOAD_RECLAMATIONS_SUCCESS = 'LOAD_RECLAMATIONS_SUCCESS'
export const LOAD_RECLAMATION = 'LOAD_RECLAMATION'
export const LOAD_RECLAMATION_SUCCESS = 'LOAD_RECLAMATION_SUCCESS'
export const EDIT_RECLAMATION = 'EDIT_RECLAMATION'
export const EDIT_RECLAMATION_SUCCESS = 'EDIT_RECLAMATION_SUCCESS'
export const DELETE_RECLAMATION = 'DELETE_RECLAMATION'
export const DELETE_RECLAMATION_SUCCESS = 'DELETE_RECLAMATION_SUCCESS'
export const LOAD_CATEGORIES = 'LOAD_CATEGORIES'
export const LOAD_STATUSES = 'LOAD_STATUSES'
export const LOAD_RECLAMATION_FILES = 'LOAD_RECLAMATION_FILES'
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS'
export const LOAD_STATUSES_SUCCESS = 'LOAD_STATUSES_SUCCESS'
export const LOAD_RECLAMATION_FILES_SUCCESS = 'LOAD_RECLAMATION_FILES_SUCCESS'

export const ADD_CONTACT = 'ADD_CONTACT'
export const ADD_CONTACT_SUCCESS = 'ADD_CONTACT_SUCCESS'
export const CONTACT_ERROR = 'CONTACT_ERROR'
export const LOAD_CONTACTS = 'LOAD_CONTACTS'
export const LOAD_CONTACTS_SUCCESS = 'LOAD_CONTACTS_SUCCESS'
export const LOAD_CONTACT = 'LOAD_CONTACT'
export const LOAD_CONTACT_SUCCESS = 'LOAD_CONTACT_SUCCESS'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const EDIT_CONTACT_SUCCESS = 'EDIT_CONTACT_SUCCESS'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACT_SUCCESS = 'DELETE_CONTACT_SUCCESS'
export const CLEAR_CONTACT = 'CLEAR_CONTACT'
export const LOAD_CONTACT_NAMES = 'LOAD_CONTACT_NAMES'
export const LOAD_CONTACT_CATEGORIES = 'LOAD_CONTACT_CATEGORIES'

export const ADD_APARTMENT = 'ADD_APARTMENT'
export const ADD_APARTMENT_SUCCESS = 'ADD_APARTMENT_SUCCESS'
export const EDIT_APARTMENT = 'EDIT_APARTMENT'
export const EDIT_APARTMENT_SUCCESS = 'EDIT_APARTMENT_SUCCESS'
export const DELETE_APARTMENT = 'DELETE_APARTMENT'
export const DELETE_APARTMENT_SUCCESS = 'DELETE_APARTMENT_SUCCESS'
export const APARTMENT_ERROR = 'APARTMENT_ERROR'

export const CONFIGURATOR = 'CONFIGURATOR'
export const CONFIGURATOR_SUCCESS = 'CONFIGURATOR_SUCCESS'
export const CONFIGURATOR_ERROR = 'CONFIGURATOR_ERROR'
export const CONFIGURATOR_UPLOAD_FILE = 'CONFIGURATOR_UPLOAD_FILE'
export const CONFIGURATOR_UPLOAD_FILE_SUCCESS =
  'CONFIGURATOR_UPLOAD_FILE_SUCCESS'
export const CONFIGURATOR_UPLOAD_FILE_ERROR = 'CONFIGURATOR_UPLOAD_FILE_ERROR'
export const CONFIGURATOR_DELETE_FILE = 'CONFIGURATOR_DELETE_FILE'
export const CONFIGURATOR_DELETE_FILE_SUCCESS =
  'CONFIGURATOR_DELETE_FILE_SUCCESS'
export const CONFIGURATOR_DELETE_FILE_ERROR = 'CONFIGURATOR_DELETE_FILE_ERROR'
export const PDF = 'PDF'
export const PDF_SUCCESS = 'PDF_SUCCESS'

export const CONFIGURATOR_LOAD = 'CONFIGURATOR_LOAD'
export const CONFIGURATOR_LOAD_SUCCESS = 'CONFIGURATOR_LOAD_SUCCESS'
export const CONFIGURATOR_LOAD_ERROR = 'CONFIGURATOR_LOAD_ERROR'

export const CONFIGURATOR_STAGE = 'CONFIGURATOR_STAGE'
export const CONFIGURATOR_STAGE_SUCCESS = 'CONFIGURATOR_STAGE_SUCCESS'
export const CONFIGURATOR_STAGE_ERROR = 'CONFIGURATOR_STAGE_ERROR'

export const INFOBOX_ADD = 'INFOBOX_ADD'
export const INFOBOX_EDIT = 'INFOBOX_EDIT'
export const INFOBOX_GET = 'INFOBOX_GET'
export const INFOBOX_ERROR = 'INFOBOX_ERROR'
export const LOAD_APARTMENTS_SUCCESS = 'LOAD_APARTMENTS_SUCCESS'
export const LOAD_APARTMENTS = 'LOAD_APARTMENTS'
export const LOAD_APARTMENT = 'LOAD_APARTMENT'
export const LOAD_APARTMENT_SUCCESS = 'LOAD_APARTMENT_SUCCESS'
export const SEND_EMAIL_APARTMENT = 'SEND_EMAIL_APARTMENT'
export const SEND_EMAIL_APARTMENT_SUCCESS = 'SEND_EMAIL_APARTMENT_SUCCESS'

export const FILE_ADD = 'FILE_ADD'
export const FILE_ADD_SUCCESS = 'FILE_ADD_SUCCESS'
export const FILE_ERROR = 'FILE_ERROR'
export const FILE_LOAD = 'FILE_LOAD'
export const FILE_LOAD_SUCCESS = 'FILE_LOAD_SUCCESS'
export const FILE_REMOVE = 'FILE_REMOVE'
export const FILE_REMOVE_SUCCESS = 'FILE_REMOVE_SUCCESS'

export const NOTIFICATION_ADD = 'NOTIFICATION_ADD'
export const NOTIFICATION_REMOVE = 'NOTIFICATION_REMOVE'
