import axios from 'axios';

import { API_ROOT, setAuthHeader } from './config';

const endpoint = `${API_ROOT}/residence`;

export default class ResidencesService {
  static submit(residence) {
    return axios.post(endpoint, residence, { headers: setAuthHeader() });
  }

  static getCities() {
    return axios.get(`${endpoint}/cities`, { headers: setAuthHeader() });
  }

  static loadById(id) {
    return axios.get(`${endpoint}/edit/${id}`, { headers: setAuthHeader() });
  }

  static loadByCity(city) {
    return axios.get(`${endpoint}/city/${city}`, { headers: setAuthHeader() });
  }

  static delete(id) {
    return axios.delete(`${endpoint}/${id}`, { headers: setAuthHeader() });
  }

  static getResidenceNames() {
    return axios.get(`${API_ROOT}/public/residence/names`);
  }

  static load() {
    return axios.get(`${API_ROOT}/public/residence/`);
  }
}
