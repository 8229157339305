
import {
    USER_REQUEST,
    USER_SUCCESS,
    USER_ERROR,
    USER_LOGOUT,
    USER_FROM_TOKEN,
    USER_FROM_TOKEN_SUCCESS,
    USER_FROM_TOKEN_ERROR,
} from '../actions/types';

const initialState = {
    data: null,
    isLoading: false,
    isAuthenticated: false,
    status: null,
    role: null,
};

const user = (state = initialState, action) => {
    switch (action.type) {
        case USER_REQUEST:
            return { ...state, isLoading: true, isAuthenticated: false, status: 'login' };
        case USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.user.data,
                role: action.payload.user.role,
                token: action.payload.token,
                isAuthenticated: true,
                status: 'authenticated',
            };
        case USER_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload.error,
                role: null,
                isAuthenticated: false,
                status: 'login',
            };
        case USER_LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                role: null,
                status: 'logout',
            };
        case USER_FROM_TOKEN:
            return {
                ...state,
                status: 'storage',
                isLoading: true,
                isAuthenticated: false,
            };
        case USER_FROM_TOKEN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                role: action.payload.user.role,
                data: action.payload.user.data,
                status: 'authenticated',
                isLoading: false,
            };
        case USER_FROM_TOKEN_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                data: null,
                role: null,
                isLoading: false,
                status: 'storage',
            };
        default:
            return state;
    }
};

export default user;
