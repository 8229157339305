import {
    INFOBOX_EDIT,
    INFOBOX_ADD,
    INFOBOX_GET,
    INFOBOX_ERROR
} from '../actions/types';

const initialState = {
    infoBoxes: [],
    infoBox: null
};

const apartments = (state = initialState, action) => {
    switch (action.type) {
        case INFOBOX_ADD:
            return { ...state, infoBox: action.payload.infoBox, infoBoxes: [...state.infoBoxes, action.payload.infoBox] };
        case INFOBOX_EDIT:
            return { ...state, infoBox: action.payload.infoBox, infoBoxes: [...state.infoBoxes.filter(ib => ib.id !== action.payload.infoBox.id), action.payload.infoBox] };
        case INFOBOX_GET:
            return { ...state, infoBox: state.infoBoxes.find(ib => ib.id === action.payload.infoBox.id) };
        case INFOBOX_ERROR:
            return { ...state, infoBoxes: [...state.infoBoxes.filter(ib => ib.id !== action.payload.infoBox.id), action.payload.infoBox] };
        default:
            return state;
    }
};

export default apartments;
