import {
    ADD_CONTACT,
    ADD_CONTACT_SUCCESS,
    CONTACT_ERROR,
    LOAD_CONTACTS,
    LOAD_CONTACTS_SUCCESS,
    DELETE_CONTACT,
    DELETE_CONTACT_SUCCESS,
    EDIT_CONTACT,
    EDIT_CONTACT_SUCCESS,
    LOAD_CONTACT,
    LOAD_CONTACT_SUCCESS,
    CLEAR_CONTACT,
    LOAD_CONTACT_NAMES,
    LOAD_CONTACT_CATEGORIES,
} from '../actions/types';

const initialState = {
    contacts: [],
    contactNames: [],
    categories: [],
    isLoading: false,
    contact: null,
    error: null,
};

const contacts = (state = initialState, action) => {
    switch (action.type) {
        case ADD_CONTACT:
        case DELETE_CONTACT:
        case EDIT_CONTACT:
        case LOAD_CONTACTS:
        case LOAD_CONTACT:
            return { ...state, isLoading: true };
        case ADD_CONTACT_SUCCESS:
            return { ...state, isLoading: false, contacts: [...state.contacts, action.payload.data.contact] };
        case DELETE_CONTACT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                contacts: state.contacts.filter(contact => contact.id !== action.payload.data.contact.id),
            };
        case EDIT_CONTACT_SUCCESS:
            return { ...state, isLoading: false };
        case LOAD_CONTACTS_SUCCESS:
            return { ...state, isLoading: false, contacts: action.payload.data.contacts };
        case LOAD_CONTACT_SUCCESS:
            return { ...state, isLoading: false, contact: action.payload.data.contact };
        case LOAD_CONTACT_NAMES:
            return { ...state, contactNames: action.payload.data.contactNames };
        case LOAD_CONTACT_CATEGORIES:
            return { ...state, categories: action.payload.data.categories };
        case CONTACT_ERROR:
            return { ...state, isLoading: false, error: action.payload };
        case CLEAR_CONTACT:
            return { ...state, contact: null };
        default:
            return state;
    }
};

export default contacts;
