import React from 'react'
import './Tabs.scss'

const Tabs = ({ children }) => (
  <div className="tab-content">
    {children}
  </div>
)

export default Tabs
