import {
    LOAD_APARTMENTS,
    LOAD_APARTMENTS_SUCCESS,
    ADD_APARTMENT,
    ADD_APARTMENT_SUCCESS,
    EDIT_APARTMENT,
    EDIT_APARTMENT_SUCCESS,
    DELETE_APARTMENT,
    DELETE_APARTMENT_SUCCESS,
    LOAD_APARTMENT,
    LOAD_APARTMENT_SUCCESS,
    SEND_EMAIL_APARTMENT,
    SEND_EMAIL_APARTMENT_SUCCESS,
    APARTMENT_ERROR
} from '../actions/types';

const initialState = {
    apartments: [],
    isLoading: false,
    apartment: null,
    error: null,
};

const apartments = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_APARTMENTS:
        case ADD_APARTMENT:
        case DELETE_APARTMENT:
        case EDIT_APARTMENT:
        case LOAD_APARTMENT:
        case SEND_EMAIL_APARTMENT:
            return { ...state, isLoading: true, apartment: null };
        case ADD_APARTMENT_SUCCESS:
            return { ...state, isLoading: false, apartments: [...state.apartments, action.payload.data.apartment] };
        case DELETE_APARTMENT_SUCCESS:
            return { ...state, isLoading: false, apartments: state.apartments.filter(apartment => apartment.id !== action.payload.data.apartment.id) };
        case EDIT_APARTMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                apartments: [...state.apartments.filter(apartment => apartment.id !== action.payload.data.apartment.id), action.payload.data.apartment],
            };
        case LOAD_APARTMENTS_SUCCESS:
            return { ...state, isLoading: false, apartments: action.payload.data.apartments };
        case LOAD_APARTMENT_SUCCESS:
            return { ...state, isLoading: false, apartment: action.payload.data.apartment };
        case SEND_EMAIL_APARTMENT_SUCCESS:
            return { ...state, isLoading: false };
        case APARTMENT_ERROR:
            return { ...state, isLoading: false, error: action.payload.error };
        default:
            return state;
    }
};

export default apartments;
