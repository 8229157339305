import React from 'react';
import { PropTypes as P } from 'prop-types';
import Button from '../Button';
import File from './File';
import './FilesList.scss'

const FilesList = ({ files, areFilesDeletable, onRemove, link }) => {
  const getNameFromPath = (pathName) => pathName.replace(/^.*[\\]/, '');
  const checkIfFileExists = (file) => !(file.indexOf('fakepath') > -1)

  return (
    <div className="files">
      <div className="row">
        <div className="col-lg-12">
          {files.map((file, index) => {
            return (
              <File fileName={getNameFromPath(file)} filePath={file} key={index} link={checkIfFileExists(file) || link}>
                {
                  areFilesDeletable &&
                  <Button className='trash_button' onClick={() => onRemove(file)} />
                }
              </File>
            );
          })}
        </div>
      </div>
    </div>
  );
}

FilesList.propTypes = {
  files: P.array.isRequired,
  areFilesDeletable: P.bool,
  onRemove: P.func,
  link: P.bool,
}

FilesList.defaultProps = {
  areFilesDeletable: false,
  onRemove() { },
  link: true,
}

export default FilesList
