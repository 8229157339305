import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header, Button, Table, TableHeader, Box, Grid, Content, ConfirmDialog, GroupButtons, Tabs, Tab } from '../../common'
import { clearString } from '../../common/utils'
import { loadResidences, deleteResidence, loadCities } from '../../actions/residences.actions'
import icoDelete from '../../assets/icons/ico-smazat-soubor.svg'
import { useHistory } from 'react-router-dom'

const pageName = 'ResidencePage'

const ResidencePage = ({ prevPageState, onPrevPageStateChange }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { residences, residenceCities, isLoading } = useSelector(state => state.residences)

  const [dialog, setDialog] = useState(null)
  const [activeCity, setActiveCity] = useState(prevPageState[pageName]?.cityTabId || 'all')

  const citiesItems = residenceCities
    .map(city => ({ id: clearString(city), name: city }))

  citiesItems.unshift({ id: 'all', name: 'Všechny' })

  useEffect(() => {
    dispatch(loadResidences())
    dispatch(loadCities())
  }, [dispatch])

  if (isLoading) return <div className='loader'>Načítám...</div>

  const encodedIdRenderer = ({ row }) => {
    const bufferId = new Buffer(`residence:${row.id}`)
    const encodedResidenceId = bufferId.toString('base64')

    return <div>{encodedResidenceId}</div>
  }

  const actionsRenderer = ({ row }) => {
    return (
      <div className='table_buttons'>
        <Button
          className='icon_button edit_button'
          onClick={() => history.push(`/admin/residence/edit/${row.id}`)}>
          Upravit
				</Button>
        <Button className='icon_button delete_button' onClick={() => handleDeleteResidence(row.id, row.name)}>
          Smazat
				</Button>
      </div>
    )
  }

  const handleDeleteResidence = (id, name) => {
    setDialog({
      id,
      show: true,
      onProceed: dialogDeleteYES,
      onCancel: () => setDialog(null),
      iconPath: icoDelete,
      title: `Opravdu chcete smazat ${name}?`,
      text: 'Objekt bude pernamentně vymazán a nebude jej možné vrátit zpět.',
      confirmText: 'Ano, smazat',
      cancelText: 'Ne, nemazat',
    })
  }

  const dialogDeleteYES = id => {
    dispatch(deleteResidence(id))
    setDialog(null)
    history.push('/admin/residence')
  }

  const onCityTabClick = cityTabId => {
    onPrevPageStateChange({
      ...prevPageState,
      [pageName]: {
        ...prevPageState[pageName] || {},
        cityTabId
      }
    })
    setActiveCity(cityTabId)
  }

  return (
    <div id='main'>
      <Header title='Přehled rezidencí'>
        <button
          className='btn btn-primary'
          onClick={() => history.push('/admin/residence/add')}>
          Přidat novou rezidenci
        </button>
      </Header>
      <div className='content'>
        <Tabs>
          <GroupButtons
            btnList={citiesItems}
            activeButton={activeCity}
            onClick={onCityTabClick}
            className="pt-3 ml-3"
          />
          {citiesItems.map(({ name, id }) => {
            const filterCities = id === 'all' ? residences : residences.filter(residence => residence.city === name)

            return (
              <Tab key={id} id={id} isActive={activeCity === id}>
                <Grid>
                  <Box>
                    <Content>
                      <Table className='table table-hover thin_rows' rows={filterCities}>
                        <TableHeader label='ID' dataId='id' />
                        <TableHeader label='Encoded ID' dataId='encodedId' cellRenderer={encodedIdRenderer} />
                        <TableHeader label='Jméno rezidence' dataId='name' />
                        <TableHeader label='Město' dataId='city' />
                        <TableHeader label='Zodpovědná osoba' dataId='brokerName' />
                        <TableHeader label='Akce' dataId='akce' cellRenderer={actionsRenderer} />
                      </Table>
                    </Content>
                  </Box>
                </Grid>
              </Tab>
            );
          })}
        </Tabs>
      </div>
      {dialog && <ConfirmDialog {...dialog} />}
    </div>
  )
}

export default ResidencePage
