import React from 'react';
import { PropTypes as P } from 'prop-types';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ iconName, title, link, isExact, isActive }) => {
  return (
    <NavLink
      exact={isExact}
      to={link}
      activeClassName="active"
      className="nav-link"
      isActive={isActive}
    >
      <span className={`icon icon-${iconName}`}></span>
      {title}
    </NavLink>
  )
}

MenuItem.propTypes = {
  iconName: P.string.isRequired,
  title: P.string.isRequired,
  link: P.string.isRequired,
  isExact: P.bool,
  isActive: P.func,
}

MenuItem.defaultProps = {
  isExact: false,
  isActive: null,
};

export default MenuItem
