import React from 'react';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '../../common';
import { logout } from '../../actions/users.actions';

const AdminMenu = () => {
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Menu isClientMenu={false}>
      <nav className="nav flex-column">
        <MenuItem title="Úvod" iconName="home" link="/admin/dashboard" />
        <MenuItem title="Reklamace" iconName="configure" link="/admin/reclamation" />
        <MenuItem title="Rezidence" iconName="residence" link="/admin/residence" />
        <MenuItem title="Kontakty" iconName="file" link="/admin/contacts" />
        <MenuItem title="Dokumenty" iconName="file" link="/admin/documents" />
        <MenuItem title="Archiv" iconName="archive" link="/admin/archive" />
      </nav>

      <nav className="nav logout flex-column">
        <a href="./" className="nav-link" onClick={handleLogout}>
          <span className="icon icon-odhlasit"></span>
          Odhlásit se
        </a>
      </nav>
    </Menu>
  );
}

export default AdminMenu;
