import React from 'react';
import { PropTypes as P } from 'prop-types';
import { FormInput } from '../../common';
import { validate } from '../../validators/loginValidator';
import { Form, Field } from 'react-final-form'

const LoginForm = ({ onSubmit }) => (
  <Form
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit}>
        <Field name="email" component={FormInput} type="email" label="E-mail" />
        <Field name="password" component={FormInput} type="password" label="Heslo" />
        <button type="submit" className="btn btn-primary">Přihlásit se</button>
      </form>
    )}
  />
)

LoginForm.propTypes = {
  onSubmit: P.func,
  invalid: P.bool,
  isClient: P.bool,
}

LoginForm.defaultProps = {
  isClient: false,
  onSubmit() { },
}

export default LoginForm;
