import { NOTIFICATION_ADD, NOTIFICATION_REMOVE } from '../actions/types';

const notification = (state = {}, action) => {
    switch (action.type) {
        case NOTIFICATION_ADD:
            return { ...state, message: action.message, isSuccess: action.isSuccess };
        case NOTIFICATION_REMOVE:
            return { ...state, message: null, isSuccess: null };
        default:
            return state;
    }
};

export default notification;
