import React from 'react';
import { PropTypes as P } from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import './TextEditor.scss'
import useDidMount from '../../hooks/useDidMount';

const API_KEY = "kml49dovsqzhtba058zfmj0t213utskfianmjp1ejhu1w7b2";

const tinymceConfig = {
  selector: 'textarea',
  language: 'cs'
}

const TextEditor = ({ onTextChange, value }) => {
  const handleEditorChange = (content) => {
    onTextChange(content);
  };

  useDidMount(() => {
    handleEditorChange(value);
  })

  return (
    <Editor
      apiKey={API_KEY}
      init={tinymceConfig}
      value={value}
      onEditorChange={handleEditorChange}
    />
  );
}

TextEditor.propTypes = {
  value: P.string,
  onTextChange: P.func,
}

TextEditor.defaultProps = {
  value: "",
  onTextChange() { },
};

export default TextEditor
