import React from 'react';
import { PropTypes as P } from 'prop-types';
import cx from 'classnames';
import { renderInfoMessage, isInvalid } from '../utils';

const FormTextarea = ({ name, label, input, meta, text }) => {
  const className = cx('form-control', { 'is-invalid': isInvalid(meta) });

  return (
    <div className="form-group col-md-12">
      <label htmlFor={name}>{label}</label>
      <textarea {...input} className={className} rows="10" cols="40" />
      {text && <small id='checkbox-label'>{text}</small>}
      {meta.touched && renderInfoMessage(meta)}
    </div>
  );
}

FormTextarea.propTypes = {
  input: P.object.isRequired,
  meta: P.object.isRequired,
  label: P.string,
  text: P.string,
}

export default FormTextarea
