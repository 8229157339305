import axios from 'axios';
import rison from 'rison';
import { webalize } from 'webalize';

import { API_ROOT, setAuthHeader } from './config';

const endpoint = `${API_ROOT}/file-manager`;

export default class FilesService {
  static async load(path, allowedExtensions) {
    if (allowedExtensions) {
      allowedExtensions = rison.encode(allowedExtensions);
    }

    return axios.get(`${endpoint}/files`, { params: { path, allowedExtensions }, headers: setAuthHeader() });
  }

  static async getReclamationFiles(path) {
    return axios.get(`${endpoint}/files`, { params: { path }, headers: setAuthHeader() });
  }

  static upload(files, path) {
    const formData = new FormData();

    formData.append('path', path);

    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return axios.post(`${endpoint}/upload`, formData);
  }

  static createFolderAndUpload(files, folder) {
    const formData = new FormData();

    this.createFolder('reklamace', folder);

    formData.append('path', `reklamace/${folder}/`);

    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return axios.post(`${endpoint}/upload`, formData);
  }

  static createFolder(path, name) {
    return axios.post(`${endpoint}/folder`,
      { params: { path, name: webalize(name) } }
    );
  }

  static remove(path) {
    return axios.delete(`${endpoint}/files`, { params: { path: encodeURI(path) }, headers: setAuthHeader() });
  }
}
