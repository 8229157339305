import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AdminMenu } from '../components'
import ReclamationAdminPage from './ReclamationAdminPage'
import ReclamationDetailsPage from './ReclamationDetailsPage'
import ResidencePage from './ResidencePage'
import ResidenceFormPage from './ResidenceFormPage'
import ContactPage from './ContactPage'
import DocumentsPage from './DocumentsPage'
import ArchivePage from './ArchivePage'
import DashboardPage from './DashboardPage'

const BasePage = ({ match }) => {
  const [prevPageState, setPrevPageState] = useState({})

  return (
    <div id='wrapper'>
      <AdminMenu />
      <Switch>
        <Route
          exact
          path={`${match.url}/dashboard`}
          render={props => (
            <DashboardPage
              {...props}
              onPrevPageStateChange={setPrevPageState}
              prevPageState={prevPageState}
            />
          )}
        />
        <Route
          exact
          path={`${match.url}/reclamation`}
          render={props => (
            <ReclamationAdminPage
              {...props}
              onPrevPageStateChange={setPrevPageState}
              prevPageState={prevPageState}
            />
          )}
        />
        <Route
          exact
          path={`${match.url}/reclamation/edit/:id`}
          component={ReclamationDetailsPage}
        />
        <Route
          exact
          path={`${match.url}/residence`}
          render={props => (
            <ResidencePage
              {...props}
              onPrevPageStateChange={setPrevPageState}
              prevPageState={prevPageState}
            />
          )}
        />
        <Route
          exact
          path={`${match.url}/residence/add`}
          component={ResidenceFormPage}
        />
        <Route
          exact
          path={`${match.url}/residence/edit/:id`}
          component={ResidenceFormPage}
        />
        <Route
          exact
          path={`${match.url}/contacts`}
          component={ContactPage}
        />
        <Route
          exact
          path={`${match.url}/documents`}
          component={DocumentsPage}
        />
        <Route
          exact
          path={`${match.url}/archive`}
          render={props => (
            <ArchivePage
              {...props}
              onPrevPageStateChange={setPrevPageState}
              prevPageState={prevPageState}
            />
          )}
        />
        <Redirect to='/admin/dashboard' />
      </Switch>
    </div>
  )
}

export default BasePage
