import React from 'react'
import { PropTypes as P } from 'prop-types'
import { includes } from 'lodash'
import Checkbox from '../Checkbox'
import './Table.scss'

const Table = ({
  vertical,
  className,
  children,
  rows,
  multisearchHeader,
  checkedItems,
  onCheckedItem
}) => {
  const columns = React.Children.map(
    children,
    child => child.props
  )

  const renderVertical = (columns) => {
    const row = rows[0]

    return (
      <React.Fragment>
        <tbody>
          {children.map((child, index) => {
            const { dataId, cellRenderer } = columns[index]

            return (
              <tr key={index}>
                {child}
                <td key={dataId}>{cellRenderer({ row, dataId, index })}</td>
              </tr>
            )
          })}
        </tbody>
      </React.Fragment>
    )
  }

  const renderHorizontal = (columns) => {
    return (
      <React.Fragment>
        <thead>
          {multisearchHeader ? (
            <tr>
              {onCheckedItem && <th />}
              {multisearchHeader}
            </tr>
          ) : null}
          <tr>
            {onCheckedItem && (
              <th>
                <Checkbox
                  id='all'
                  checked={checkedItems.length === rows.length}
                  onChange={() => onCheckedItem('all')}
                />
              </th>
            )}
            {children}
          </tr>
        </thead>

        <tbody>
          {rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>Žádná data k zobrazení.</td>
            </tr>
          )}
          {rows.map((row, index) => {
            return (
              <tr key={row.id}>
                {onCheckedItem && (
                  <td>
                    <Checkbox
                      id={row.id}
                      checked={includes(checkedItems, row.id)}
                      onChange={() => onCheckedItem(row.id)}
                    />
                  </td>
                )}
                {columns.map(({ dataId, cellRenderer }, i) => (
                  <td
                    key={i}
                    className={dataId === 'id' || dataId === 'byt' ? 'narrow-col' : undefined}>
                    {cellRenderer({ row, dataId, index })}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </React.Fragment>
    )
  }

  return (
    <table
      className={className ? className : undefined}>
      {vertical
        ? renderVertical(columns)
        : renderHorizontal(columns)}
    </table>
  )
}

Table.propTypes = {
  rows: P.array.isRequired,
  multisearchHeader: P.any,
  vertical: P.bool,
  onCheckedItem: P.func
}

Table.defaultProps = {
  vertical: false
}

export default Table
