import React from 'react';
import { PropTypes as P } from 'prop-types';
import { Link } from 'react-router-dom';
import defaultLogoPath from '../../assets/bafire.png';
import './Menu.scss'

const Menu = ({ children, logoPath }) => (
  <div id="sidebar" role="navigation">
    <div className="brand d-flex align-items-center justify-content-center">
      <Link to='/admin'>
        <img src={logoPath} alt="logo" />
      </Link>
    </div>
    {children}
  </div>
)

Menu.propTypes = {
  isClientMenu: P.bool,
  logoPath: P.string,
  children: P.any,
}

Menu.defaultProps = {
  isClientMenu: true,
  logoPath: defaultLogoPath,
};

export default Menu
