import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_ERROR,
  USER_LOGOUT,
  USER_FROM_TOKEN,
  USER_FROM_TOKEN_SUCCESS,
  USER_FROM_TOKEN_ERROR,
} from './types';
import UserService from '../services/users.service';
import { addNotification } from './notification.actions';
import { reset } from 'redux-form';

export function login(email, password) {
  return async dispatch => {
    try {
      dispatch({ type: USER_REQUEST });

      const response = await UserService.login(email, password);

      sessionStorage.setItem('jwtToken', response.data.token);

      dispatch({
        type: USER_SUCCESS,
        payload: response.data,
      });

      dispatch(addNotification('Přihlášení uživatele bylo úspěšné.', true));
    }
    catch (ex) {
      dispatch({
        type: USER_ERROR,
        payload: ex,
      });

      dispatch(reset('login'));

      if (ex?.response?.status === 401) {
        dispatch(addNotification('Jméno nebo heslo není správné.', false));
      } else {
        dispatch(addNotification('Při přihlášení došlo k chybě. Zkuste to později.', false));
      }
    }
  };
}

export function logout() {
  return async dispatch => {
    dispatch({ type: USER_LOGOUT });

    sessionStorage.removeItem('jwtToken');

    dispatch(addNotification('Uživatel byl odhlášen.', true));
  };
}

export function loadFromToken() {
  return async dispatch => {
    try {
      const token = sessionStorage.getItem('jwtToken');

      if (!token || token === '') {
        return;
      }

      dispatch({ type: USER_FROM_TOKEN });

      const response = await UserService.loadFromToken(token);

      sessionStorage.setItem('jwtToken', response.data.token);

      dispatch({ type: USER_FROM_TOKEN_SUCCESS, payload: response.data });
    }
    catch (error) {
      dispatch({ type: USER_FROM_TOKEN_ERROR, payload: error });

      sessionStorage.removeItem('jwtToken');

      if (error?.response?.status === 401) {
        dispatch(addNotification('Relace vypršela. Přihlašte se prosím znovu', false));
      } else {
        dispatch(addNotification('Při přihlášení došlo k chybě. Zkuste to později.', false));
      }
    }
  };
}
