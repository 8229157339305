import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tabs, GroupButtons } from '../../common'
import { loadStatuses, loadCategories } from '../../actions/reclamations.actions'
import { loadResidences, loadResidenceNames, loadCities } from '../../actions/residences.actions'
import { clearString } from '../../common/utils'
import ReclamationByCityTab from './ReclamationByCityTab'

export const ReclamationsList = ({
  reclamationsList,
  prevPageState,
  onPrevPageStateChange,
  setCheckedItems,
  checkedItems,
  pageName
}) => {
  const dispatch = useDispatch()

  const [activeCity, setActiveCity] = useState(prevPageState[pageName]?.cityTabId || 'all')

  const residencesState = useSelector(state => state.residences)
  const reclamationsState = useSelector(state => state.reclamations)

  const {
    isLoadingResidences,
    isLoadingResidenceNames,
    isLoading: isLoadingResidencesState,
    residenceCities,
    residences
  } = residencesState

  const {
    isLoadingReclamations,
    isLoadingCategories,
    isLoadingStatuses,
    isLoading: isLoadingReclamationsState,
  } = reclamationsState

  const reclamationsWithCity = reclamationsList.map((reclamation) => {
    const reclamationResidence = residences.find((residence) => residence.name === reclamation.rezidence)

    return { ...reclamation, city: reclamationResidence?.city || null }
  })

  const citiesItems = residenceCities
    .map(city => ({ id: clearString(city), name: city }))

  citiesItems.unshift({ id: 'all', name: 'Všechny' })

  const isLoading =
    isLoadingReclamations ||
    isLoadingCategories ||
    isLoadingStatuses ||
    isLoadingResidences ||
    isLoadingResidenceNames ||
    isLoadingReclamationsState ||
    isLoadingResidencesState

  useEffect(() => {
    dispatch(loadResidences())
    dispatch(loadCategories())
    dispatch(loadResidenceNames())
    dispatch(loadStatuses())
    dispatch(loadResidences())
    dispatch(loadCities())
  }, [dispatch])

  useEffect(() => {
    setCheckedItems([])
  }, [activeCity, setCheckedItems])

  if (isLoading) return <div className='loader'>Načítám...</div>

  const setSearchQueries = searchQueries => {
    onPrevPageStateChange({
      ...prevPageState,
      [pageName]: {
        ...prevPageState[pageName],
        searchQueries
      }
    })
  }

  const onCityTabClick = cityTabId => {
    setActiveCity(cityTabId)
    onPrevPageStateChange({
      ...prevPageState,
      [pageName]: {
        ...prevPageState[pageName] || {},
        cityTabId
      }
    })
  }

  return (
    <div className='content'>
      <Tabs>
        <GroupButtons
          btnList={citiesItems}
          activeButton={activeCity}
          onClick={onCityTabClick}
          className="pt-3 ml-3"
        />
        {citiesItems.map(({ id, name }) => {
          const filteredByActiveCity = id === 'all' ? reclamationsWithCity : reclamationsWithCity.filter(reclamation => reclamation.city === name)

          return (
            <ReclamationByCityTab
              reclamationsByCity={filteredByActiveCity}
              currentCityId={id}
              isActiveTab={activeCity === id}
              setSearchQueries={setSearchQueries}
              prevSearchQueries={prevPageState[pageName]?.searchQueries || undefined}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
            />
          )
        })}
      </Tabs>
    </div>
  )
}

export default ReclamationsList
