import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { Notification, withAuthentization } from './common';
import { LoginPage, BasePage, ConfirmationPage } from './admin/pages'
import { ReclamationPage, SubmittingPage, SubmittedPage } from './client/pages';
import './App.scss';

const SecuredAdminBasePage = withAuthentization('admin')(BasePage)

function App() {
  return (
    <React.Fragment>
      <Notification />
      <Switch>
        <Route exact path='/admin/login' component={LoginPage} />
        <Route path='/admin' component={SecuredAdminBasePage} />
        <Route path='/submitting' component={SubmittingPage} />
        <Route path='/submitted' component={SubmittedPage} />
        <Route exact path={`/reclamation/potvrzeni/:id`} component={ConfirmationPage} />
        <Route path='/:id?' component={ReclamationPage} />
      </Switch>
    </React.Fragment>
  );
}

export default App;
