const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const PHONE_REGEX = /^(\+42(0|1))? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/i;

export const required = value => 
    (value ? undefined : 'Toto pole je povinné');
    
export const requiredSelect = value =>
    (value && value !== 'Prosím vyberte' ? undefined : 'Toto pole je povinné');

export const emailValidation = value =>
    (value && EMAIL_REGEX.test(value) ? undefined : "Zadejte email ve správném formátu");

export const phoneValidation = value =>
    (value && PHONE_REGEX.test(value) ? undefined : "Zadejte telefonní čislo ve správném formátu");

