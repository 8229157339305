import axios from 'axios'

import { API_ROOT, setAuthHeader } from './config'

export default class ReclamationService {
	static submit(data) {
		return axios.post(`${API_ROOT}/reclamation/submit`, data)
	}

	static edit(data) {
		return axios.post(`${API_ROOT}/admin/reclamation/edit`, data, {
			headers: setAuthHeader()
		})
	}

	static contactCompany(id) {
		return axios.get(`${API_ROOT}/admin/reclamation/contact/${id}`, {
			headers: setAuthHeader()
		})
	}

	static list() {
		return axios.get(`${API_ROOT}/admin/reclamation/list`, {
			headers: setAuthHeader()
		})
	}
	static getByID(id) {
		return axios.get(`${API_ROOT}/admin/reclamation/edit/${id}`, {
			headers: setAuthHeader()
		})
	}
	static confirm(id) {
		return axios.get(`${API_ROOT}/reclamation/confirm/${id}`)
	}
	static getCategories() {
		return axios.get(`${API_ROOT}/reclamation/categories`)
	}

	static getStatuses() {
		return axios.get(`${API_ROOT}/reclamation/statuses`)
	}

	static getByIDs(ids) {
		const idsParam = ids.join(',')
		return axios.get(`${API_ROOT}/reclamation/export/${idsParam}`)
	}
}
