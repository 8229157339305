import { NOTIFICATION_ADD, NOTIFICATION_REMOVE } from './types';

export function addNotification(message, isSuccess) {
    return dispatch => {
        dispatch({
            message,
            isSuccess,
            type: NOTIFICATION_ADD,
        });
    };
}

export function removeNotification() {
    return dispatch => {
        dispatch({
            type: NOTIFICATION_REMOVE,
        });
    };
}
