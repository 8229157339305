import React from 'react';
import { PropTypes as P } from 'prop-types';
import './Dialog.scss'

const ReclamationModal = ({ onProceed, confirmText }) => (
  <div>
    <div className="modal-show">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <h1>Co (ne)reklamovat?</h1>
                  <h2>Některé běžné závady reklamaci nevyžadují. Zvažte zda nejste schopni závadu odstranit sami před jejím nahlášením.</h2>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <h3>Pípá Vám na chodbě požární čidlo?</h3>
                    <p>Pípání požárního čidla je důsledkem končící životnosti baterie. Úspěšně jej odstraníte výměnou baterie.</p>
                  </div>
                  <div>
                    <h3>Neodtéká Vám odpad v kuchyňském dřezu či v koupelně?</h3>
                    <p>Odpad je potřeba vyčistit od hrubých nečistot, například důsledným omytím nebo použitím chemie k tomu určené.</p>
                  </div>
                  <div>
                    <h3>Máte pocit, že topení ve Vašem bytě topí málo?</h3>
                    <p>Možnou příčinou je jeho zavzdušnění. Doporučujeme jej odvzdušnit pomocí odvzdušňovacího ventilu.</p>
                  </div>
                  <div>
                    <h3>Nesvítí Vám některé ze světel?</h3>
                    <p>Zkontrolujte jistič příslušného světa. Je v pořádku? Přejděte ke kontrole samotné žárovky. Jestli je prasklá, vyměňte ji.</p>
                  </div>
                  <div>
                    <h3>Nefungují elektrické zásuvky ani vypínače světel?</h3>
                    <p>Zkontrolujte jističe v bytovém rozvaděči, případně hlavní jistič na společné chodbě. Pokud je vše v pořádku, podejte reklamaci, může se totiž jednat o závadu na vodiči.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <h3>Opětovně vypadávají jističe?</h3>
                    <p>Je možné, že máte na okruhu připojeno příliš mnoho spotřebičů. Zkuste některý z nich z okruhu odpojit či ho napojte na jiný okruh.</p>
                  </div>
                  <div>
                    <h3>Je manipulace s okenními nebo dveřními křídly ztížená?</h3>
                    <p>Ztíženou manipulaci s okenními nebo dveřními křídly byste měli odstranit jejich seřízením.</p>
                  </div>
                  <div>
                    <h3>Objevují se na zdech nebo v rozích praskliny?</h3>
                    <p>Tento jev je běžný po dobu cca 3-4 let u nových domů, které vysychají a pracují. Opadávající omítku však reklamujte.</p>
                  </div>
                  <div>
                    <h3>Teče Vám slabý proud vody z vodovodní baterie?</h3>
                    <p>Průtok vody z jakékoliv vodovodní baterie zlepšíte vyčištěním sítka baterie od hrubých nečistot.</p>
                  </div>
                  <div>
                    <h3>Došlo k poškození koupelny vlivem vlhkosti, např. k plísním?</h3>
                    <p>Poškození vlivem vlhkosti dochází často  z důvodu nedostatečného větrání. V případě zatečení ze střechy nebo ze sousedního bytu pokračujte k reklamaci.</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  <h3>Nalezená závada neodpovídá výčtu běžných závad výše či jste tipy na jejich odstranění vyzkoušeli a problém stále přetrvává? Přejděte, prosím, k podání reklamace.</h3>
                </div>
              </div>
              <div className="row">
                <div className="col text-center"><button type="button" className="btn btn-primary" onClick={() => onProceed()}>{confirmText}</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

ReclamationModal.propTypes = {
  show: P.bool,
  onProceed: P.func,
  confirmText: P.string,
}

ReclamationModal.defaultProps = {
  confirmText: 'Potvrdit',
  show: false,
  onProceed: () => null,
};

export default ReclamationModal
