import React from 'react';
import { PropTypes as P } from 'prop-types';
import './Table.scss'

const defaultCellRenderer = ({ row, dataId }) => {
  if (!dataId) return '-';

  const paths = dataId.split('.');

  let result = row[paths[0]];

  for (let i = 1; i < paths.length; i++) {
    result = result[paths[i]];
  }

  return result;
}

const TableHeader = ({ dataId, label }) => (
  <th className={(dataId === "emptyRow") ? "blueTableHeader" : ""}>
    {label}
  </th>
)

TableHeader.propTypes = {
  label: P.string,
  dataId: P.string,
  cellRenderer: P.func,
}

TableHeader.defaultProps = {
  label: '',
  cellRenderer: defaultCellRenderer,
}

export default TableHeader
