import {
    CONFIGURATOR,
    CONFIGURATOR_SUCCESS,
    CONFIGURATOR_ERROR,
    CONFIGURATOR_LOAD,
    CONFIGURATOR_LOAD_SUCCESS,
    CONFIGURATOR_LOAD_ERROR,
    CONFIGURATOR_UPLOAD_FILE,
    CONFIGURATOR_UPLOAD_FILE_SUCCESS,
    CONFIGURATOR_UPLOAD_FILE_ERROR,
    CONFIGURATOR_DELETE_FILE,
    CONFIGURATOR_DELETE_FILE_SUCCESS,
    CONFIGURATOR_DELETE_FILE_ERROR,
    CONFIGURATOR_STAGE,
    CONFIGURATOR_STAGE_SUCCESS,
    CONFIGURATOR_STAGE_ERROR,
    PDF,
    PDF_SUCCESS,
} from '../actions/types';

const initialState = {
    configurator: null,
    error: null,
};

const configurator = (state = initialState, action) => {
    switch (action.type) {

        case CONFIGURATOR:
        case CONFIGURATOR_LOAD:
        case CONFIGURATOR_UPLOAD_FILE:
        case CONFIGURATOR_DELETE_FILE:
        case CONFIGURATOR_STAGE:
        case PDF:
            return { ...state, isLoading: true };

        case CONFIGURATOR_SUCCESS:
            return { ...state, isLoading: false, configurator: action.payload.data.configurator };

        case CONFIGURATOR_ERROR:
            return { ...state, isLoading: false, error: action.payload.error };

        case CONFIGURATOR_LOAD_SUCCESS:
        case CONFIGURATOR_STAGE_SUCCESS:
            return { ...state, isLoading: false, configurator: action.payload.data.configurator };

        case CONFIGURATOR_LOAD_ERROR:
        case CONFIGURATOR_STAGE_ERROR:
            return { ...state, isLoading: false, error: action.payload.error };

        case CONFIGURATOR_UPLOAD_FILE_SUCCESS:
            return { ...state, isLoading: false }

        case CONFIGURATOR_UPLOAD_FILE_ERROR:
            return { ...state, isLoading: false, error: action.payload.error }

        case CONFIGURATOR_DELETE_FILE_SUCCESS:
            return { ...state, isLoading: false }

        case CONFIGURATOR_DELETE_FILE_ERROR:
            return { ...state, isLoading: false, error: action.payload.error }

        case PDF_SUCCESS:
            return { ...state, isLoading: false };
        default:
            return state;

    }
};

export default configurator;
