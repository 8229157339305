import ReclamationService from '../services/reclamation.service'
import { addNotification } from './notification.actions'
import {
  LOAD_RECLAMATIONS,
  LOAD_RECLAMATIONS_SUCCESS,
  RECLAMATION_ERROR,
  LOAD_RECLAMATION,
  LOAD_RECLAMATION_SUCCESS,
  LOAD_CATEGORIES,
  LOAD_STATUSES,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_STATUSES_SUCCESS,
  LOAD_RECLAMATION_FILES
} from './types'
import FilesService from '../services/files.service'
import omit from 'lodash/omit'
import { saveAs } from 'file-saver'
import { removeHtml } from '../common/utils'

export function submitReclamation(data, history) {
  return async dispatch => {
    try {
      const reclamation = { ...omit(data, ['files']) }

      const response = await ReclamationService.submit(reclamation)

      await FilesService.createFolderAndUpload(data.files, response.data.folder)

      history.push({
        pathname: '/submitted',
        state: { submitted: true },
      });
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })
      dispatch(addNotification('Reklamaci se nepodařilo ulozit.', false))
    }
  }
}

export function editReclamation(data) {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RECLAMATIONS })

      await ReclamationService.edit(data)
      const request = await ReclamationService.list()

      dispatch({ type: LOAD_RECLAMATIONS_SUCCESS, payload: request })

      dispatch(addNotification('Reklamace upravena!', true))
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })
      dispatch(addNotification('Reklamaci se nepodařilo upravit.', false))
    }
  }
}


export function confirm(data) {
  return async dispatch => {
    try {
      await ReclamationService.confirm(data)
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })
    }
  }
}

export function loadReclamations() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RECLAMATIONS })

      const request = await ReclamationService.list()

      dispatch({
        type: LOAD_RECLAMATIONS_SUCCESS,
        payload: request
      })
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })

      dispatch(addNotification('Reklamaci se nepodařilo načíst.', false))
    }
  }
}

export function loadReclamation(id) {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_RECLAMATION })

      const request = await ReclamationService.getByID(id)

      dispatch({
        type: LOAD_RECLAMATION_SUCCESS,
        payload: request
      })

      const filesRequest = await FilesService.getReclamationFiles(
        'reklamace/' + request.data.reclamation.folder
      )

      dispatch({
        type: LOAD_RECLAMATION_FILES,
        payload: filesRequest
      })
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })

      dispatch(addNotification('Reklamaci se nepodařilo načíst.', false))
    }
  }
}

export function contactCompany(id) {
  return async dispatch => {
    try {
      await ReclamationService.contactCompany(id)

      dispatch(addNotification('Email odeslan!', true))
      dispatch({ type: LOAD_RECLAMATIONS })

      const request = await ReclamationService.list()

      dispatch({
        type: LOAD_RECLAMATIONS_SUCCESS,
        payload: request
      })
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })

      dispatch(addNotification('Email se nepodařilo odeslat.', false))
    }
  }
}

export function loadCategories() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_CATEGORIES })

      const categories = await ReclamationService.getCategories()

      dispatch({
        type: LOAD_CATEGORIES_SUCCESS,
        payload: categories
      })
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })

      dispatch(addNotification('Kategorie se nepodařilo načíst.', false))
    }
  }
}

export function loadStatuses() {
  return async dispatch => {
    try {
      dispatch({ type: LOAD_STATUSES })

      const statuses = await ReclamationService.getStatuses()

      dispatch({
        type: LOAD_STATUSES_SUCCESS,
        payload: statuses
      })
    } catch (e) {
      dispatch({
        type: RECLAMATION_ERROR,
        payload: e
      })

      dispatch(addNotification('Kategorie se nepodařilo načíst.', false))
    }
  }
}

export function exportData(ids) {
  return async dispatch => {
    try {
      const request = await ReclamationService.getByIDs(ids)
      const date = new Date()
      const fullDate =
        date.getDate() + '_' + date.getMonth() + '_' + date.getFullYear()
      let csvContent =
        '\ufeff' +
        'Interní evidenční číslo;Bytový dům;Stav reklamace;Popis závady\r\n'

      request.data.reclamations.forEach(function (r) {
        csvContent += `${r.id};${r.rezidence};${r.status};"${removeHtml(
          r.internalText
        )}"\r\n`
      })

      var blob = new Blob([csvContent], {
        type: 'text/plain;charset=utf-8'
      })

      saveAs(blob, `export_reklamace_${fullDate}.csv`)

      dispatch(addNotification('Export úspěšný.', true))
    } catch (e) {
      dispatch(addNotification('Export se nezdařil.', false))
    }
  }
}
