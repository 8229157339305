import React from 'react';
import { PropTypes as P } from 'prop-types';
import url from 'url';
import cx from 'classnames';
import pdfIcon from '../../assets/icons/pdf_ico.svg';
import docIcon from '../../assets/icons/doc_ico.svg';
import xlsIcon from '../../assets/icons/xls_ico.svg';
import imageIcon from '../../assets/icons/image_ico.svg';
import defaultFileIco from '../../assets/icons/defaultFile_ico.svg'
import { domain } from '../../services/config';
import './FilesList.scss'

const File = ({ children, link, fileName, filePath }) => {
  const getFileExtension = (path) => path.split('.').pop();

  const getIcon = (filename) => {
    let extension = getFileExtension(filename).toLowerCase();

    switch (extension) {
      case 'pdf': return pdfIcon;
      case 'xls', 'xlsx': return xlsIcon;
      case 'doc', 'docx': return docIcon;
      case 'png', 'jpg', 'jpeg': return imageIcon;
      default: return defaultFileIco;
    }
  }

  return (
    <div className={cx('miniFile', { "noHover": !link })}>
      <a
        href={url.resolve(domain, filePath)}
        target="_blank"
        rel="noopener noreferrer"
        className={link ? "linkFile" : "linkFile disable-link"}
      >
        <img src={getIcon(fileName)} width='30' alt='pdf' />
        <span>{fileName}</span>
      </a>
      {children}
    </div>
  );
}

File.propTypes = {
  fileName: P.string.isRequired,
  filePath: P.string.isRequired,
  link: P.bool
}

File.defaultProps = {
  link: true
}

export default File
