import FilesService from '../services/files.service';
import { addNotification } from './notification.actions';
import {
  FILE_ADD,
  FILE_ADD_SUCCESS,
  FILE_ERROR,
  FILE_LOAD,
  FILE_LOAD_SUCCESS,
  FILE_REMOVE,
  FILE_REMOVE_SUCCESS,
} from './types';

export function load(path, allowedExtensions) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_LOAD });

      const request = await FilesService.load(path, allowedExtensions);

      dispatch({
        type: FILE_LOAD_SUCCESS,
        payload: request,
      });
    }
    catch (exception) {
      dispatch({
        type: FILE_ERROR,
        payload: exception,
      });
    }
  };
}

export function upload(files, path = null) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_ADD });
      const request = await FilesService.upload(files, path);
      dispatch({
        type: FILE_ADD_SUCCESS,
        payload: request,
      });

      dispatch(addNotification('Nahráno.', true));
    }
    catch (e) {
      dispatch({
        type: FILE_ERROR,
        payload: e,
      });

      dispatch(addNotification('Nahrávání nebylo úspěšné.', false));
    }
  };
}

export function createFolderAndUpload(files, name) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_ADD });

      const request = await FilesService.createFolderAndUpload(files, name);

      dispatch({
        type: FILE_ADD_SUCCESS,
        payload: request,
      });

      dispatch(addNotification('Složka vytvořena.', true));
    }
    catch (e) {
      dispatch({
        type: FILE_ERROR,
        payload: e,
      });

      dispatch(addNotification('Složku se nepodařilo vytvořit.', false));
    }
  };
}

export function createFolder(path, name) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_ADD });

      const request = await FilesService.createFolder(path, name);

      dispatch({
        type: FILE_ADD_SUCCESS,
        payload: request,
      });

      dispatch(addNotification('Složka vytvořena.', true));
    }
    catch (e) {
      dispatch({
        type: FILE_ERROR,
        payload: e,
      });

      dispatch(addNotification('Složku se nepodařilo vytvořit.', false));
    }
  };
}

export function remove(path) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_REMOVE });

      const request = await FilesService.remove(path);

      dispatch({
        type: FILE_REMOVE_SUCCESS,
        payload: request,
      });

      dispatch(addNotification('Smazáno.', true));
    }
    catch (e) {
      dispatch({
        type: FILE_ERROR,
        payload: e,
      });

      dispatch(addNotification('Mazání nebylo úspěšné.', false));
    }
  };
}

export function getReclamationFiles(path) {
  return async dispatch => {
    try {
      dispatch({ type: FILE_LOAD });

      const request = await FilesService.getReclamationFiles(path);

      dispatch({
        type: FILE_LOAD_SUCCESS,
        payload: request,
      });
    }
    catch (exception) {
      dispatch({
        type: FILE_ERROR,
        payload: exception,
      });
    }
  };
}

