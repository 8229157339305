import React from 'react'
import { useDispatch } from 'react-redux'

import useDidMount from '../../hooks/useDidMount';
import { FormWrapper, Box, Title } from '../../common';
import { confirm } from '../../actions/reclamations.actions';

const ConfirmationPage = ({ match }) => {
  const dispatch = useDispatch()

  useDidMount(() => dispatch(confirm(match.params.id)))

  return (
    <>
      <FormWrapper class='form-wrapper'>
        <Title title='Reklamační formulář' />
        <Box>
          <span className='span-header'>Děkujeme za potvrzení.</span>
        </Box>
      </FormWrapper>
    </>
  )
}

export default ConfirmationPage
