import React from 'react'
import url from 'url'
import { domain } from '../services/config'

export function getUrl(path, isStatic = false) {
  if (!path) {
    return null
  }

  return isStatic ? path : url.resolve(domain, path)
}

export function removeHtml(str) {
  var div = document.createElement('div')
  div.innerHTML = str
  return div.innerText
}

export function renderInfoMessage({ error, warning }) {
  const errorMessage = error && <div className="invalid-feedback">{error}</div>;
  const warningMessage = warning && <div className="invalid-feedback">{warning}</div>;

  return (
    errorMessage || warningMessage
  );
}

export function isInvalid({ error, touched }) {
  return touched && error;
}

export function getDateWithTime(hours, minutes) {
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
}

export const getTodaysDateString = () => {
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }

  return dd + '/' + mm + '/' + yyyy;
};


export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)


export const clearString = str =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

