import React from 'react'
import { PropTypes as P } from 'prop-types';
import './Button.scss'

const Button = ({ children, className, onClick, id, type, isDisabled }) => (
  <button
    type={type}
    className={className}
    disabled={isDisabled}
    onClick={() => onClick(id)}
  >
    {children}
  </button>
)

Button.propTypes = {
  id: P.string,
  type: P.string,
  isDisabled: P.bool,
  onClick: P.func,
};

Button.defaultProps = {
  type: "button",
  isDisabled: false,
  onClick() { },
}

export default Button
