import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from '../../common'
import { filter } from 'lodash'
import { exportData, loadReclamations } from '../../actions/reclamations.actions'
import { addNotification } from '../../actions/notification.actions'
import ReclamationsList from '../components/ReclamationsList'

export const ArchivePage = ({ prevPageState, onPrevPageStateChange }) => {
  const dispatch = useDispatch()
  const [checkedItems, setCheckedItems] = useState([])

  const { reclamations, isLoadingReclamations } = useSelector(state => state.reclamations)

  const filteredReclamations = filter(reclamations, reclamation => reclamation.archived).reverse()

  useEffect(() => {
    dispatch(loadReclamations())
  }, [dispatch])

  const handleExport = () => {
    if (checkedItems.length > 0) {
      dispatch(exportData(checkedItems))
    } else {
      dispatch(addNotification('Vyberte položky k exportu', false))
    }
  }

  if (isLoadingReclamations) return <div className='loader'>Načítám...</div>

  return (
    <div id='main'>
      <Header title='Archivované reklamace'>
        <button type='button' className='btn btn-primary' onClick={handleExport}>
          Export
        </button>
      </Header>
      <ReclamationsList
        reclamationsList={filteredReclamations}
        prevPageState={prevPageState}
        onPrevPageStateChange={onPrevPageStateChange}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        pageName="ArchivePage"
      />
    </div>
  )
}

export default ArchivePage
