import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form'
import { Box, Content, Grid, Header, FormTextEditor, FormSelect, Table, TableHeader, FilesList } from '../../common';
import { loadReclamation, editReclamation, loadStatuses } from '../../actions/reclamations.actions';
import useDidMount from '../../hooks/useDidMount';
import { useHistory } from 'react-router-dom';

const ReclamationDetailsPage = ({ match }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { reclamation, statuses = [], files, isLoadingReclamation, isLoadingStatuses } = useSelector(state => state.reclamations)
  const statusItems = statuses.map((s) => ({ id: s.label, name: s.label }));

  useDidMount(() => {
    dispatch(loadReclamation(match.params.id));
    dispatch(loadStatuses())
  })

  if (isLoadingReclamation || isLoadingStatuses) return <div className="loader">Načítám...</div>

  const handleSubmit = (data) => {
    dispatch(editReclamation(data));
    history.push('/admin/reclamation');
  };

  const complaintInfoHeaders = [
    <TableHeader key="1" label="Jméno rezidence" dataId="rezidence" />,
    <TableHeader key="2" label="Číslo bytu" dataId="byt" />,
    <TableHeader key="3" label="Jméno majitele bytu" dataId="jmenoPrimary" />,
    <TableHeader key="4" label="Telefon majitele" dataId="telefonPrimary" />,
    <TableHeader key="5" label="Email majitele" dataId="emailPrimary" />,
  ];

  const secondComplaintInfoHeaders = [
    <TableHeader key="3" label="Jméno" dataId="jmenoSecondary" />,
    <TableHeader key="4" label="Telefon" dataId="telefonSecondary" />,
    <TableHeader key="5" label="Email" dataId="emailSecondary" />,
  ];

  const feederInfoHeaders = [
    <TableHeader key="1" label="Datum podání" dataId="datumPodani" />,
    <TableHeader key="2" label="Datum zjištení" dataId="datumZjisteni" />,
    <TableHeader key="3" label="Kategorie závady" dataId="kategorie" />,
    <TableHeader key="4" label="Návrh termínu prohlídky" dataId="prohlidka" />,
    <TableHeader key="5" label="Popis závady" dataId="zavada" />,
  ];

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={reclamation}
      render={({ handleSubmit }) => (
        <div id="main">
          <form onSubmit={handleSubmit}>
            <Header title={`Detail reklamace - ${reclamation.id}`}>
              <button className="btn btn-danger"
                onClick={() => {
                  history.push("/admin/reclamation");
                }}>Zpět</button>
              <button type="submit" className="btn btn-primary">Uložit</button>
            </Header>

            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <Content>
                    <Grid isScrollable={false}>
                      <Box>
                        <Content>
                          <Field
                            name="status"
                            val={reclamation.state}
                            label="Stav reklamace"
                            component={FormSelect}
                            items={[{}, ...statusItems]}
                          />
                          <Field
                            val={reclamation.internalText}
                            name={`internalText`}
                            component={FormTextEditor}
                            label='Interní textová poznámka'
                          />
                        </Content>
                      </Box>
                      <div>
                        <Box>
                          <Content title="Informace o závadě" className="mb-30">
                            <Table rows={[reclamation]} className="table table-hover" vertical={true}>
                              {feederInfoHeaders}
                            </Table>
                          </Content>
                          {(files.length) ?
                            <Content title="Přílohy k reklamaci">
                              <FilesList files={files} areFilesDeletable={false} />
                            </Content>
                            : null
                          }
                        </Box>
                        <Box>
                          <Content title="Informace o podávajícím">
                            <Table rows={[reclamation]} className="table table-hover" vertical={true}>
                              {complaintInfoHeaders}
                            </Table>
                          </Content>
                          {
                            (reclamation.jmenoSecondary) ?
                              <Content title="Byt zpřístupní">
                                <Table rows={[reclamation]} className="table table-hover" vertical={true}>
                                  {secondComplaintInfoHeaders}
                                </Table>
                              </Content>
                              : null
                          }
                        </Box>
                      </div>
                    </Grid>
                  </Content>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    />
  )
}

export default ReclamationDetailsPage
