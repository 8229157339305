import React, { useState } from 'react'
import { PropTypes as P } from 'prop-types'
import diacritics from 'diacritics'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Table from './Table'

// TODO (Armana): Refactor

const removeDiacritics = diacritics.remove

const SearchTable = props => {
  const initSearchQueries =
    props.prevSearchQueries ||
    props.filterableColumns.map(fc => ({
      dataId: fc.dataId,
      searchQuery: '',
      label: fc.label,
      type: fc.type,
      options: fc.options
    }))

  const [searchQueries, setSearchQueries] = useState(initSearchQueries)

  const filterThatShit = (searchQueries, rows) => {
    const filteredRows = rows.filter(row => {
      const matches = searchQueries.filter(i => {
        const colData = Object.values(pick(row, i.dataId))[0]
          ? Object.values(pick(row, i.dataId))[0].toString()
          : ''

        return !removeDiacritics(colData)
          .toLowerCase()
          .includes(removeDiacritics(i.searchQuery).toLowerCase())
      })

      return !matches.length
    })

    return filteredRows
  }

  const clearFilter = () => {
    const clearedFilter = searchQueries.map(function (sq) {
      return { ...sq, searchQuery: '' }
    })
    props.setPrevSearchQueries(clearedFilter)
    setSearchQueries(clearedFilter)
  }

  const renderSearchInputs = () => {
    if (searchQueries) {
      const items = searchQueries.map((item, index) => {
        return (
          <th key={index} className={'search-header'}>
            {item.dataId ? (
              renderInputs(item)
            ) : (
                <button
                  onClick={() => clearFilter()}
                  className='btn button-gray clearFilterBtn'>
                  Resetovat
                </button>
              )}
          </th>
        )
      })
      return items
    }
  }

  const renderInputs = (item) => {
    switch (item.type) {
      case 'dropdown': {
        return (
          <div className='select-input'>
            <select
              value={item.searchQuery}
              onChange={e => handleSearch(e, item)}
              className={'form-control search-header'}>
              {item.options.map(renderSelectOptions)}
            </select>
          </div>
        )
      }
      case 'date': {
        return (
          <div className='select-input select-input-date'>
            <DatePicker
              className={'form-control search-header-field'}
              onChange={value => handleSearchDatePicker(value, item)}
              dateFormat='dd/MM/yyyy'
              placeholderText='DD/MM/RRRR'
              value={item.searchQuery ? item.searchQuery : null}
              onKeyDown={() =>
                handleSearch({ target: { value: '' } }, item)
              }
            />
          </div>
        )
      }
      default: {
        return (
          <input
            className={'form-control search-header-field'}
            value={item.searchQuery}
            placeholder={item.label}
            type={item.type || 'text'}
            onChange={e => handleSearch(e, item)}
          />
        )
      }
    }
  }

  const renderSelectOptions = (option, index) => (
    <option key={index} value={option.id}>
      {option.name}
    </option>
  )

  const handleSearch = (event, item) => {
    const filteredSearchQueries = searchQueries.filter(i => i !== item)

    const newSearchQueries = props.filterableColumns.map(fc => {
      if (item.dataId === fc.dataId) {
        const searchQuery =
          event.target.value === '-' ? '' : event.target.value

        return { ...fc, dataId: fc.dataId, searchQuery, label: fc.label }
      } else {
        const searchQuery =
          event.target.value === '-'
            ? ''
            : filteredSearchQueries.find(i => {
              return i.dataId === fc.dataId
            }).searchQuery

        return { ...fc, dataId: fc.dataId, searchQuery, label: fc.label }
      }
    })

    props.setPrevSearchQueries(newSearchQueries)
    setSearchQueries(newSearchQueries)
  }

  const handleSearchDatePicker = (value, item) => {
    if (moment(value).isValid()) {
      handleSearch(
        { target: { value: moment(value).format('DD/MM/YYYY') } },
        item
      )
    }
  }

  const filteredRows = filterThatShit(
    searchQueries,
    props.rows
  )

  return (
    <React.Fragment>
      <div id='filterArea'>
        <div className='row' />
      </div>
      <Table
        multisearchHeader={renderSearchInputs()}
        rows={filteredRows}
        clearFilter={clearFilter}
        {...omit(props, [
          'rows',
          'filterableColumns',
          'placeholder',
          'prevSearchQueries',
          'setPrevSearchQueries'
        ])}
      />
    </React.Fragment>
  )
}

SearchTable.propTypes = {
  rows: P.array.isRequired,
  filterableColumns: P.arrayOf(
    P.shape({
      dataId: P.string,
      label: P.string,
      type: P.string,
      options: P.array
    }).isRequired
  ),
  placeholder: P.string,
  prevSearchQueries: P.arrayOf(
    P.shape({
      dataId: P.string,
      searchQuery: P.string,
      label: P.string,
      type: P.string,
      options: P.array
    }).isRequired
  ),
  setPrevSearchQueries: P.func
}

SearchTable.defaultProps = {
  placeholder: 'Zadejte hledanou frázi'
}

export default SearchTable
