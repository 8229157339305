import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loadFromToken as adminLoadFromToken } from '../actions/users.actions';
import useDidMount from '../hooks/useDidMount';

export const withAuthentization = (allowedRole) => (WrappedComponent) => {
  const SecuredPage = (props) => {
    const { isAuthenticated, isLoading, role } = useSelector(state => state.user)
    const dispatch = useDispatch()

    useDidMount(() => {
      if (!isAuthenticated) dispatch(adminLoadFromToken());
    })

    if (isLoading) return <div>Načítám...</div>;

    if (isAuthenticated && role === allowedRole) return <WrappedComponent {...props} />

    return <Redirect to={{
      pathname: '/admin/login',
      state: { referrer: props.location.pathname }
    }} />;
  }

  return SecuredPage
};
