const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export function validate (values) {
    const errors = {};

    if (!values.email) {
        errors.email = 'Toto pole je povinné';
    }
    else if (!EMAIL_REGEX.test(values.email)) {
        errors.email = 'Zadejte email ve správném formátu';
    }

    if (!values.password) {
        errors.password = 'Toto pole je povinné';
    }

    return errors;
}
