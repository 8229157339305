import React, { createRef, useEffect, useState } from 'react';
import { PropTypes as P } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { default as pathFn } from 'path';

import FileItem from './FileItem';
import { Box, Content, ConfirmDialog, Dropzone } from '../../../common';
import { getUrl } from '../../../common/utils';
import icoDelete from '../../../assets/icons/ico-smazat-soubor.svg';

import { upload, load, createFolder, remove } from '../../../actions/files.actions';
import { addNotification } from '../../../actions/notification.actions';
import './FileManager.scss'


const FileManager = ({
  isMultipleSelectAllowed,
  allowedDisplayExtensions,
  onSelect
}) => {
  const dispatch = useDispatch()
  const { isLoading, items, error } = useSelector(state => state.files)

  const [path, setPath] = useState('/')
  const [isUploadVisible, setIsUploadVisible] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [dialog, setDialog] = useState(null)

  const newFolderRef = createRef()

  useEffect(() => {
    dispatch(load(path, allowedDisplayExtensions))
  }, [dispatch, allowedDisplayExtensions, path])

  const renderFilesList = (items) => {
    const prevUrl = path.replace(/^uploads/g, '/').split('/');

    prevUrl.pop();

    const folders = items.filter(item => item.type === 'folder').sort(sortFilesByName);
    const files = items.filter(item => item.type !== 'folder').sort(sortFilesByName);

    items = folders.concat(files);

    if (isLoading) return <p>Nahrávám...</p>;

    return (
      <React.Fragment>
        {path !== '/' &&
          <FileItem
            name=".."
            path={prevUrl.join('/')}
            type="folder"
            onClick={handleFileItemClick}
            onDoubleClick={handleFileItemDoubleClick}
          />}
        {items.map((file) =>
          <FileItem
            key={file.path}
            name={file.name}
            path={file.path}
            type={file.type}
            isSelected={selectedItems.includes(file.path)}
            onClick={handleFileItemClick}
            onDoubleClick={handleFileItemDoubleClick}
            onDeleteClick={handleDeleteClick}
          />
        )}
      </React.Fragment>
    );
  }

  const renderBar = () => (
    <React.Fragment>
      <div className="bar__breadcrumb">
        {constructBreadcrumb()}
      </div>

      <div className="bar__new-folder">
        <div className="form-inline">
          <div className="form-group mb-2">
            <input
              ref={newFolderRef}
              type="text"
              className="form-control"
              placeholder="Název nové složky"
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCreateFolder}
            >
              Vytvořit složku
            </button>
          </div>
        </div>
      </div>

      <div className="bar__settings">
        <button type="button" className="toggle-upload btn btn-primary" onClick={handleToggleUpload}>+ Nahrát soubory</button>
      </div>
    </React.Fragment>
  )

  const constructBreadcrumb = () => {
    const parts = path.replace(/^uploads/g, '').split('/').filter(String);
    let currentPath = '/';
    const items = [{ path: '/', name: 'root' }];

    parts.forEach((part) => {
      currentPath = pathFn.join(currentPath, part);

      items.push({ path: currentPath, name: part });
    });

    return (
      <ul>
        {items.map((item) =>
          <li key={item.path}>
            <a href="#" onClick={() => loadFromPath(item.path)}>{item.name}</a>
          </li>
        )}
      </ul>
    );
  }

  const handleUploadFiles = (files) => {
    dispatch(upload(files, path));
  };

  const handleFileItemClick = (type, path) => {
    if (isMultipleSelectAllowed) {
      const newSelectedItems = new Set(selectedItems);

      if (newSelectedItems.has(path)) {
        newSelectedItems.delete(path);
      } else {
        newSelectedItems.add(path);
      }

      setSelectedItems(Array.from(newSelectedItems))
      onSelect(selectedItems)
    }
    else {
      setSelectedItems([path])
      onSelect(selectedItems)
    }
  };

  const handleFileItemDoubleClick = (type, path) => {
    if (type === 'folder') {
      setPath(path)
      setSelectedItems([])
      dispatch(load(path, allowedDisplayExtensions))
    } else if (type !== 'folder' && type !== 'generic') {
      window.open(getUrl(path), '_blank');
    }
  };

  const handleCreateFolder = () => {
    if (newFolderRef.current.value) {
      dispatch(createFolder(path, newFolderRef.current.value));
      newFolderRef.current.value = null;
    }
  };

  const handleToggleUpload = () => {
    setIsUploadVisible(!isUploadVisible)
  };

  const handleDeleteClick = (path) => {
    setDialog({
      id: path,
      show: true,
      onProceed: dialogDeleteYES,
      onCancel: dialogReset,
      iconPath: icoDelete,
      title: `Opravdu chcete smazat soubor${path}?`,
      text: 'Objekt bude pernamentně vymazán a nebude jej možné vrátit zpět.',
      confirmText: 'Ano, smazat',
      cancelText: 'Ne, nemazat',
    })
  };

  const dialogDeleteYES = (path) => {
    const newSelectedItems = new Set(selectedItems);

    if (newSelectedItems.has(path)) {
      newSelectedItems.delete(path);
    }

    setSelectedItems(Array.from(newSelectedItems))
    dispatch(remove(path));
    onSelect(selectedItems);
    dialogReset();
  };

  const dialogReset = () => setDialog(null)

  const loadFromPath = (path) => {
    setPath(path)
    setSelectedItems([])
    dispatch(load(path, allowedDisplayExtensions))
  }

  const sortFilesByName = (file1, file2) => {
    file1 = file1.name.toLowerCase();
    file2 = file2.name.toLowerCase();

    if (file1 < file2)
      return -1;
    if (file1 > file2)
      return 1;
    return 0;
  }

  if (error) {
    dispatch(addNotification('Při načítání souboru se vyskytla chyba', false));
  }

  return (
    <Box>
      <Content>
        <div className="file-manager">
          <div className="file-manager__bar">
            {renderBar()}
          </div>

          {
            isUploadVisible &&
            <Dropzone
              handleUploadFiles={handleUploadFiles}
              isUploadInProgress={isLoading}
            />
          }
          <div className="file-manager__list">
            {renderFilesList(items)}
          </div>
        </div>

        {dialog && <ConfirmDialog {...dialog} />}
      </Content>
    </Box>
  );
}

FileManager.propTypes = {
  isMultipleSelectAllowed: P.bool,
  allowedDisplayExtensions: P.arrayOf(P.string),
  onSelect: P.func,
}

FileManager.defaultProps = {
  isMultipleSelectAllowed: true,
  onSelect() { },
};

export default FileManager
