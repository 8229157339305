import React from 'react';
import './FormWrapper.scss';

const FormWrapper = ({ children }) => {
  const style = {
    margin: "40px 0px"
  };

  return (
    <div style={style}>
      <div className="FormWrapper">
        <div className="row d-flex align-items-center justify-content-center">
          <div className="col-lg-8">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormWrapper
