import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from '../../common'
import { loadReclamations, exportData, } from '../../actions/reclamations.actions'
import { addNotification } from '../../actions/notification.actions'
import ReclamationsList from '../components/ReclamationsList'
import { filter } from 'lodash'
import moment from 'moment'

export const DashboardPage = ({ prevPageState, onPrevPageStateChange }) => {
  const dispatch = useDispatch()

  const [checkedItems, setCheckedItems] = useState([])

  const { reclamations, isLoadingReclamations } = useSelector(state => state.reclamations)

  const filteredReclamations = filter(reclamations, reclamation => {
    const datumPodani = moment(reclamation.datumPodani, "DD/MM/YYYY")
    const dateBeforeTwoWeeks = moment().subtract(14, 'd');
    return datumPodani.isBefore(dateBeforeTwoWeeks)
      && !reclamation.archived
      && (
        reclamation.status === 'Podaná' ||
        reclamation.status === 'Zkontrolovaná' ||
        reclamation.status === 'Předána k odstranění'
      )
  })

  useEffect(() => {
    dispatch(loadReclamations())
  }, [dispatch])

  const handleExport = () => {
    if (checkedItems.length > 0) {
      dispatch(exportData(checkedItems))
    } else {
      dispatch(addNotification('Vyberte položky k exportu', false))
    }
  }

  if (isLoadingReclamations) return <div className='loader'>Načítám...</div>

  return (
    <div id='main'>
      <Header title='Úvod - Nevyřízené reklamace'>
        <button type='button' className='btn btn-primary' onClick={handleExport}>
          Export
        </button>
      </Header>
      <ReclamationsList
        reclamationsList={filteredReclamations}
        prevPageState={prevPageState}
        onPrevPageStateChange={onPrevPageStateChange}
        setCheckedItems={setCheckedItems}
        checkedItems={checkedItems}
        pageName="DashboardPage"
      />
    </div>
  )
}

export default DashboardPage
