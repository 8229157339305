import React from 'react';
import { PropTypes as P } from 'prop-types';
import cx from 'classnames';
import { renderInfoMessage, isInvalid } from '../utils';
import useDidMount from '../../hooks/useDidMount';

const FormInput = ({ input, label, meta, val, placeholder }) => {
  const className = cx('form-control', { 'is-invalid': isInvalid(meta) });
  const ph = placeholder ? placeholder : label;

  useDidMount(() => {
    if (val) input.onChange(val);
  })

  return (
    <div className={cx('form-group', { 'hidden': (input.type === "hidden") })}>
      <label htmlFor={input.name}>{label}</label>
      <input
        {...input}
        placeholder={ph}
        className={className}
      />

      {meta.touched && renderInfoMessage(meta)}
    </div>
  );
}

FormInput.propTypes = {
  val: P.string,
  input: P.object.isRequired,
  meta: P.object.isRequired,
  label: P.string,
  placeholder: P.string,
  text: P.string,
}

export default FormInput
