import React from 'react';
import { PropTypes as P } from 'prop-types';
import { Field } from 'react-final-form';
import FormSlider from '../FormSlider';
import './FormCheckboxArea.scss';

const FormCheckboxArea = ({ name, label, input }) => {
  return (
    <div className="form-group col-md-12">
      <div className="checkbox-area">
        <div className="row onoffswitch">
          <div className="col-md-8">{label}</div>
          <div className="col-md-4">
            <Field
              className="onoffswitch-checkbox"
              component={FormSlider}
              id="myCheck"
              name={name || input.name}
              type="checkbox"
              checked={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

FormCheckboxArea.propTypes = {
  input: P.object,
  label: P.string,
  name: P.string,
}

export default FormCheckboxArea
