import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import { removeNotification } from '../../actions/notification.actions';
import './Notification.scss'

const DELAY_TIME = 5000;

export const Notification = () => {
  const { message, isSuccess } = useSelector(state => state.notification);
  const dispatch = useDispatch()
  let timer = null

  useEffect(() => {
    if (message) setTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message])

  const handleCloseAlert = () => {
    dispatch(removeNotification());
  }

  const setTimer = () => {
    if (timer != null) clearTimeout(timer);
    timer = setTimeout(() => {
      handleCloseAlert()
      clearTimeout(timer)
    }, DELAY_TIME);
  }

  const className = cx('alert alert-dismissible fade show animated', {
    'success': isSuccess,
    'failure': !isSuccess,
  });

  if (!message) return <div />

  return (
    <div className="notification">
      <div className={className} role="alert">
        {message}
        <Button onClick={handleCloseAlert} className='close transparent_button'>
          <span aria-hidden='true'>&times;</span>
        </Button>
      </div>
    </div>
  );
}

export default Notification
