import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Header, FormTextEditor, Box, FormInput, Content, Grid, Table, TableHeader, Button, ConfirmDialog } from '../../common';
import icoDelete from '../../assets/icons/ico-smazat-soubor.svg';
import {
  loadContacts,
  loadContact,
  deleteContact,
  submitContact,
  clearContact,
} from './../../actions/contacts.actions';
import { Form, Field } from 'react-final-form';

const ContactPage = () => {
  const dispatch = useDispatch()
  const [dialog, setDialog] = useState(null)
  const { contact, contacts } = useSelector(state => state.contacts)

  useEffect(() => {
    dispatch(loadContacts());
  }, [dispatch])

  const actionsRenderer = ({ row }) => (
    <div className="table__buttons">
      <Button
        className="icon_button edit_button"
        onClick={() => onloadContact(row.id)}
      >
        Editovat
      </Button>
      <Button
        className="icon_button delete_button"
        onClick={() => handleDeleteContact(row.id, row.name)}
      >
        Smazat
      </Button>
    </div>
  )

  const onSubmit = (data) => {
    dispatch(submitContact(data));
    dispatch(loadContacts());
    dispatch(clearContact());
  };

  const onloadContact = (id) => {
    dispatch(loadContact(id));
  };

  const handleDeleteContact = (id, name) => {
    setDialog({
      id,
      show: true,
      onProceed: dialogDeleteYES,
      onCancel: () => setDialog(null),
      iconPath: icoDelete,
      title: `Opravdu chcete smazat ${name}?`,
      text: 'Objekt bude pernamentně vymazán a nebude jej možné vrátit zpět.',
      confirmText: 'Ano, smazat',
      cancelText: 'Ne, nemazat',
    })
  };

  const dialogDeleteYES = (id) => {
    dispatch(deleteContact(id));
    setDialog(null)
    dispatch(loadContacts());
  };

  return (
    <div id="main">
      <Header title="Kontakty" />
      <Form
        keepDirty
        onSubmit={onSubmit}
        initialValues={contact ? contact : {}}
        render={({ handleSubmit, form }) => (
          <form onSubmit={event => {
            handleSubmit(event);
            form.reset()
          }}>
            <div className="content">
              <div className="row">
                <div className="col-md-12">
                  <Content>
                    <Grid isScrollable={false}>
                      <Box>
                        <Content title="Přidat kontakt">
                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                name={`name`}
                                type='text'
                                component={FormInput}
                                label='Jméno firmy - identifikator'
                                placeholder='Zadejte jméno firmy'
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <Field
                                name={`contact`}
                                component={FormTextEditor}
                                label='Kontaktní adresa'
                                placeholder='Kontaktní adresa'
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <Field
                                name={`telefon`}
                                type='tel'
                                component={FormInput}
                                label='Telefon'
                                placeholder='Telefon'
                              />
                            </div>
                            <div className="col-md-6">
                              <Field
                                name={`email`}
                                type='email'
                                component={FormInput}
                                label='Email'
                                placeholder='Email'
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="buttons-line">
                              <div className="col-md-12">
                                <button type="submit" className="btn btn-primary">
                                  {contact && contact.id ? 'Editovat' : 'Vytvořit'}
                                </button>
                                {
                                  contact && contact.id &&
                                  <button type="button" className="btn btn-danger" onClick={() => form.reset}>Zrušit</button>
                                }
                              </div>
                            </div>
                          </div>
                        </Content>
                      </Box>
                      <Box>
                        <Content title="Výpis kontaktů">
                          {
                            contacts &&
                            <Table rows={contacts} className="table table-hover">
                              <TableHeader label="Jméno" dataId="name" />
                              <TableHeader label="Akce" cellRenderer={actionsRenderer} />
                            </Table>
                          }
                        </Content>
                      </Box>
                    </Grid>
                  </Content>
                </div>
              </div>
              {dialog && <ConfirmDialog {...dialog} />}
            </div>
          </form>
        )}
      />
    </div>
  );
}

export default ContactPage
