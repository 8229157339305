import React from 'react'
import { required, phoneValidation, emailValidation } from '../../../validators/reclamationValidator';
import { FormCheckboxArea, FormInput, Box } from '../../../common';
import { Field } from 'react-final-form'
import { composeValidators } from '../../../common/utils';

const OwnerContactInformationSection = ({ secondContact }) => {
  const additionalContactInformationSection = (
    <React.Fragment>
      <h3 className="h3-reclamaction">Kontaktní údaje toho, kdo zpřístupní byt</h3>
      <div className='row'>
        <div className='col-md-4'>
          <Field
            name={`jmenoSecondary`}
            type='text'
            component={FormInput}
            label='Jméno a příjmení'
            placeholder='Zadejte jméno'
            validate={required}
          />
        </div>
        <div className='col-md-4'>
          <Field
            name={`emailSecondary`}
            type='email'
            component={FormInput}
            label='e-mail'
            placeholder='Zadejte e-mail'
            validate={composeValidators(required, emailValidation)}
          />
        </div>
        <div className='col-md-4'>
          <Field
            name={`telefonSecondary`}
            type='tel'
            component={FormInput}
            label='telefon'
            placeholder='Zadejte telefon'
            validate={composeValidators(required, phoneValidation)}
          />
        </div>
      </div>
    </React.Fragment>
  )

  return (
    <Box>
      <h3 className="h3-reclamaction">Kontaktní údaje majitele</h3>
      <div className='row'>
        <div className='col-md-4'>
          <Field
            name={`jmenoPrimary`}
            type='text'
            component={FormInput}
            label='Jméno a příjmení majitele bytu'
            placeholder='Zadejte jméno majitele bytu'
            validate={required}
          />
        </div>
        <div className='col-md-4'>
          <Field
            name={`emailPrimary`}
            type='email'
            component={FormInput}
            label='e-mail majitele'
            placeholder='Zadejte e-mail majitele'
            validate={composeValidators(required, emailValidation)}
          />
        </div>
        <div className='col-md-4'>
          <Field
            name={`telefonPrimary`}
            type='tel'
            component={FormInput}
            label='telefon majitele'
            placeholder='Zadejte telefon majitele'
            validate={composeValidators(required, phoneValidation)}
          />
        </div>
      </div>
      <div className='row'>
        <Field
          name='secondContact'
          type='checkbox'
          component={FormCheckboxArea}
          label='Liší se kontaktní údaje toho, kdo zpřístupní byt od majitele bytu?'
        />

      </div>
      {secondContact && additionalContactInformationSection}
    </Box>
  )
}

export default OwnerContactInformationSection
