import React from 'react'
import { PropTypes as P } from 'prop-types'
import { renderInfoMessage, isInvalid } from '../utils'
import cx from 'classnames'
import './FormSelect.scss'

const FormSelect = ({ disabled, input, label, meta, items }) => {
  const classNameFormControl = cx('form-control', {
    'is-invalid': isInvalid(meta),
    'is-disabled': disabled,
  })
  const classNameGroupControl = cx('form-group', {
    'is-invalid': isInvalid(meta),
    'is-disabled': disabled,
  })

  const renderSelectOptions = (option, index) => (
    <option key={index} value={option.id}>
      {option.name}
    </option>
  )

  return (
    <div className={classNameGroupControl}>
      <label htmlFor={input.name}>{label}</label>
      <div className='select-input'>
        <select {...input} className={classNameFormControl} disabled={disabled}>
          {items.map(renderSelectOptions)}
        </select>
      </div>
      {meta.touched && renderInfoMessage(meta)}
    </div>
  )
}

FormSelect.propTypes = {
  disabled: P.bool,
  input: P.object.isRequired,
  meta: P.object.isRequired,
  label: P.string,
  placeholder: P.string,
}

FormSelect.defaultProps = {
  disabled: false,
}

export default FormSelect
