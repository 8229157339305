import React, { useCallback } from 'react'
import { PropTypes as P } from 'prop-types';
import { useDropzone } from 'react-dropzone'
import './Dropzone.scss'

const Dropzone = ({ handleUploadFiles, isUploadInProgress }) => {
  const onDrop = useCallback((files) => handleUploadFiles(files), [handleUploadFiles])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.pdf,.xls,.xlsx,.doc,.docx,.jpg,.jpeg,.png,.svg,.heic' })

  return (
    <div {...getRootProps()} className='dropzone'>
      <input {...getInputProps()} />
      {
        isUploadInProgress ?
          <p>Drop the files here ...</p> :
          <p>Drag 'n' drop some files here, or click to select files</p>
      }
    </div>
  )
}

Dropzone.propTypes = {
  handleUploadFiles: P.func.isRequired,
  isUploadInProgress: P.bool.isRequired
}

export default Dropzone;
